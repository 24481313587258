import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import api from '../api'; import StatusSelector from './ProjectStatusSelector';
import TodoList from './TodoList';
import ProjectSelector from './ProjectTypeSelector';
import ProjectSidebar from './ProjectSidebar';
import ProjectTodoListActions from './ProjectTodoListActions';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

// Create a debounced function for updating project notes
const debouncedUpdateProjectNotes = debounce(async (projectId, notes) => {
    try {
        await api.put(`${API_BASE_URL}/projects/${projectId}`, { notes });
    } catch (error) {
        console.error('Error updating project notes:', error);
    }
}, 500);

// Add a new debounced function for updating project title
const debouncedUpdateProjectTitle = debounce(async (projectId, name) => {
    try {
        await api.put(`${API_BASE_URL}/projects/${projectId}`, { name });
    } catch (error) {
        console.error('Error updating project title:', error);
    }
}, 500);

const Projects = React.memo(({
    isSidebarExpanded,
    onToggleSidebar,
    projects,
    updateProjects,
    onSendToWeeklyPlanner,
    weeklyTodos,
    onUpdateProject,
    selectedProjectId,
    onSelectProject
}) => {
    const [isLoading, setIsLoading] = useState(true);


    const reorderTodos = (todos) => {
        if (!todos || !Array.isArray(todos)) return [];
        return [...todos].sort((a, b) => {
            // First, sort by completion status
            if (a.completed !== b.completed) {
                return a.completed ? 1 : -1;
            }
            // Then, sort by order
            return a.order - b.order;
        });
    };

    const selectedProject = useMemo(() => {
        const project = projects.find(p => p.id === selectedProjectId);
        if (project) {
            return { ...project, todos: reorderTodos(project.todos || []) };
        }
        return null;
    }, [projects, selectedProjectId]);

    const handleReorderTodos = useCallback(async (newTodos) => {
        if (selectedProject) {
            try {
                const todoIds = newTodos.map(todo => todo.id);
                await api.put(`${API_BASE_URL}/projects/${selectedProject.id}/todos/reorder`, { todos: todoIds });

                // Update local state
                updateProjects(prevProjects => prevProjects.map(p =>
                    p.id === selectedProject.id ? { ...p, todos: newTodos } : p
                ));
            } catch (error) {
                console.error('Error reordering todos:', error);
                // TODO: Add user-friendly error handling
            }
        }
    }, [selectedProject, updateProjects]);

    const fetchProjects = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`${API_BASE_URL}/projects`, {
                params: { include: 'todos' }
            });
            const projectsWithTodos = response.data.map(project => ({
                ...project,
                todos: project.todos || []
            }));
            updateProjects(projectsWithTodos);
            if (projectsWithTodos.length > 0 && !selectedProjectId) {
                onSelectProject(projectsWithTodos[0].id);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {
            setIsLoading(false);
        }
    }, [updateProjects, selectedProjectId, onSelectProject]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const handleAddProject = useCallback(async () => {
        try {
            const newProject = {
                name: "Untitled Project",
                status: 'notStarted',
                type: 'UNCATEGORIZED',
                notes: '',
                todos: [],
            };
            const response = await api.post(`${API_BASE_URL}/projects`, newProject);
            updateProjects(prevProjects => [...prevProjects, response.data]);
            onSelectProject(response.data.id);
        } catch (error) {
            console.error('Error adding project:', error);
        }
    }, [updateProjects, onSelectProject]);

    const handleSelectProject = useCallback((project) => {
        onSelectProject(project.id);
    }, [onSelectProject]);

    const handleProjectUpdate = useCallback(async (updatedProject) => {
        try {
            const response = await api.put(`${API_BASE_URL}/projects/${updatedProject.id}`, updatedProject);
            const updatedProjects = projects.map(p =>
                p.id === updatedProject.id ? response.data : p
            );
            updateProjects(updatedProjects);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    }, [projects, updateProjects]);

    const handleNotesChange = useCallback((e) => {
        const newNotes = e.target.value;
        if (selectedProject) {
            // Update local state immediately for responsiveness
            updateProjects(prevProjects => prevProjects.map(project =>
                project.id === selectedProject.id ? { ...project, notes: newNotes } : project
            ));
            // Debounce the API call
            debouncedUpdateProjectNotes(selectedProject.id, newNotes);
        }
    }, [selectedProject, updateProjects]);

    const handleTitleChange = useCallback((e) => {
        const newTitle = e.target.value;
        if (selectedProject) {
            // Update local state immediately for responsiveness
            updateProjects(prevProjects => prevProjects.map(project =>
                project.id === selectedProject.id ? { ...project, name: newTitle } : project
            ));
            // Debounce the API call
            debouncedUpdateProjectTitle(selectedProject.id, newTitle);
        }
    }, [selectedProject, updateProjects]);

    const handleProjectDelete = useCallback(async (projectId) => {
        try {
            await api.delete(`${API_BASE_URL}/projects/${projectId}`);
            updateProjects(prevProjects => prevProjects.filter(p => p.id !== projectId));
            if (selectedProjectId === projectId) {
                onSelectProject(null);
            }
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    }, [updateProjects, selectedProjectId, onSelectProject]);

    const handleSendToWeeklyPlanner = useCallback(async (todo) => {
        if (selectedProject) {
            try {
                const response = await api.put(`${API_BASE_URL}/projects/${selectedProject.id}/todos/${todo.id}/send-to-weekly`, {
                    projectName: selectedProject.name
                });
                if (response.data) {
                    const updatedProject = {
                        ...selectedProject,
                        todos: selectedProject.todos.map(t =>
                            t.id === todo.id ? { ...t, sentToWeeklyPlanner: true } : t
                        )
                    };
                    await handleProjectUpdate(updatedProject);
                    // Return the response data for MainContainer to handle
                    return response.data;
                }
            } catch (error) {
                console.error('Error sending todo to weekly planner:', error);
                // You might want to show an error message to the user here
            }
        }
        return null;
    }, [selectedProject, handleProjectUpdate]);


    const handleTodosChange = useCallback((newTodos) => {
        if (selectedProject) {
            console.log('Updating todos for project:', selectedProject.id, 'New todos:', newTodos);

            // Update local state
            updateProjects(prevProjects => prevProjects.map(p =>
                p.id === selectedProject.id ? { ...p, todos: newTodos } : p
            ));

            // Communicate changes to MainContainer
            newTodos.forEach(todo => {
                const originalTodo = selectedProject.todos.find(t => t.id === todo.id);
                if (originalTodo && (originalTodo.completed !== todo.completed || originalTodo.urgent !== todo.urgent)) {
                    onUpdateProject(selectedProject.id, todo.id, {
                        completed: todo.completed,
                        urgent: todo.urgent
                    });
                }
            });
        }
    }, [selectedProject, updateProjects, onUpdateProject]);

    const handleUpdateProjectsOrder = useCallback(async (reorderedProjects) => {
        try {
            // Optimistically update the local state
            updateProjects(reorderedProjects);

            // Send only the project IDs in the new order to the backend
            const projectIds = reorderedProjects.map(p => p.id);
            const response = await api.put(`${API_BASE_URL}/projects/reorder`, { projects: projectIds });

            // Update the projects with the response from the server
            updateProjects(response.data);
        } catch (error) {
            console.error('Error updating projects order:', error);
            // Revert to the original order if there's an error
            await fetchProjects();
            // TODO: Add user-friendly error handling, e.g., show an error toast
        }
    }, [updateProjects, fetchProjects]);

    const renderProjectTodoListActions = useCallback(({ todo, onDelete, onToggleUrgent }) => (
        <ProjectTodoListActions
            todo={todo}
            onDelete={onDelete}
            onToggleUrgent={onToggleUrgent}
            onSendToWeeklyPlanner={async (todo) => {
                const result = await handleSendToWeeklyPlanner(todo);
                if (result && onSendToWeeklyPlanner) {
                    onSendToWeeklyPlanner(result.projectTodo, result.weeklyTodo);
                }
            }}
            isTaskInWeeklyPlanner={(todoId) => weeklyTodos.some(wt => wt.originalTodoId === todoId)}
        />
    ), [handleSendToWeeklyPlanner, weeklyTodos, onSendToWeeklyPlanner]);

    return (
        <div className="flex h-full relative">
            <ProjectSidebar
                projects={projects}
                selectedProject={selectedProject}
                onSelectProject={handleSelectProject}
                onAddProject={handleAddProject}
                onDeleteProject={handleProjectDelete}
                onUpdateProjectsOrder={handleUpdateProjectsOrder}
                isExpanded={isSidebarExpanded}
                onToggleSidebar={onToggleSidebar}
            />
            <div
                className={`flex-grow p-6 overflow-y-auto transition-all duration-300 ease-in-out ${isSidebarExpanded ? 'ml-64' : 'ml-16'
                    }`}
            >
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Projects</h2>
                </div>
                {isLoading ? (
                    <p>Loading...</p>
                ) : selectedProject ? (
                    <div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium mb-1" htmlFor="projectTitle">Title</label>
                            <input
                                type="text"
                                id="projectTitle"
                                value={selectedProject.name}
                                onChange={handleTitleChange}
                                className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Project Title"
                                autoComplete="off"
                            />
                        </div>

                        <ProjectSelector
                            selectedType={selectedProject.type}
                            onTypeChange={(newType) => handleProjectUpdate({ ...selectedProject, type: newType })}
                        />

                        <div className="mb-4">
                            <StatusSelector
                                status={selectedProject.status}
                                onStatusChange={(newStatus) => handleProjectUpdate({ ...selectedProject, status: newStatus })}
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block text-sm font-medium mb-1" htmlFor="projectNotes">Notes</label>
                            <textarea
                                id="projectNotes"
                                value={selectedProject.notes}
                                onChange={handleNotesChange}
                                className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                rows="4"
                                placeholder="Project notes..."
                            />
                        </div>
                            <div className="mt-4">
                                <label className="block text-sm font-medium mb-1">Todo List:</label>
                                <TodoList
                                    todos={selectedProject?.todos || []}
                                    onTodosChange={handleTodosChange}
                                    onReorderTodos={handleReorderTodos}
                                    onTaskCompletion={(taskId, updates) => onUpdateProject(selectedProject.id, taskId, updates)}
                                    isWeeklyPlanner={false}
                                    weeklyTodos={weeklyTodos}
                                    projects={projects}
                                    renderActions={renderProjectTodoListActions}
                                    projectId={selectedProject?.id}
                                />
                            </div>
                    </div>
                ) : (
                    <p className="text-gray-400">Select a project from the sidebar or create a new one.</p>
                )}
            </div>
        </div>
    );
});

export default Projects;
import React, { useState, useEffect, useRef, useCallback } from 'react';

const SendToProjectDialog = ({ open, onClose, projects, onSendToProject, onCreateNewProject, todo, isSendAll }) => {
    const [selectedProject, setSelectedProject] = useState('');
    const [newProjectName, setNewProjectName] = useState('');
    const dialogRef = useRef(null);

    const handleSendToProject = useCallback(async () => {
        if (selectedProject === 'new') {
            if (newProjectName.trim()) {
                try {
                    const newProjectId = await onCreateNewProject(newProjectName);
                    if (isSendAll) {
                        await onSendToProject(newProjectId);
                    } else {
                        await onSendToProject(todo, newProjectId);
                    }
                } catch (error) {
                    console.error('Error creating new project and sending todo(s):', error);
                }
            }
        } else {
            if (isSendAll) {
                await onSendToProject(selectedProject);
            } else {
                await onSendToProject(todo, selectedProject);
            }
        }
        onClose();
    }, [selectedProject, newProjectName, onCreateNewProject, onSendToProject, todo, onClose, isSendAll]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            handleSendToProject();
        }
    }, [handleSendToProject]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, onClose, handleKeyDown]);

    const handleProjectChange = (e) => {
        setSelectedProject(e.target.value);
        if (e.target.value !== 'new') {
            setNewProjectName('');
        }
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div ref={dialogRef} className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
                <h2 className="text-xl font-bold mb-4 text-gray-200">
                    {isSendAll ? "Send All Todos to Project" : "Send to Project"}
                </h2>
                <div className="mb-4">
                    <select
                        value={selectedProject}
                        onChange={handleProjectChange}
                        onKeyDown={handleKeyDown}
                        className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select a project</option>
                        {projects.map((project) => (
                            <option key={project.id} value={project.id}>
                                {project.name}
                            </option>
                        ))}
                        <option value="new">...New Project</option>
                    </select>
                </div>
                {selectedProject === 'new' && (
                    <div className="mb-4">
                        <input
                            type="text"
                            value={newProjectName}
                            onChange={(e) => setNewProjectName(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="New Project Name"
                            className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                )}
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded hover:bg-gray-700 transition-colors duration-200"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSendToProject}
                        disabled={!selectedProject || (selectedProject === 'new' && !newProjectName.trim())}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {isSendAll ? "Send All to Project" : "Send to Project"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SendToProjectDialog;
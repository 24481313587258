import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import api from '../api';
import MeetingsSidebar from './MeetingsSidebar';
import TodoList from './TodoList';
import MeetingsTodoListActions from './MeetingsTodoListActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { isValid, parseISO } from 'date-fns';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

// Saving database operations
const debouncedUpdateMeetingNotes = debounce(async (meetingId, notes) => {
    try {
        await api.put(`${API_BASE_URL}/meetings/${meetingId}`, { notes });
    } catch (error) {
        console.error('Error updating meeting notes:', error);
    }
}, 500); // 500ms debounce time, adjust as needed

// Add a new debounced function for updating meeting title
const debouncedUpdateMeetingTitle = debounce(async (meetingId, title) => {
    try {
        await api.put(`${API_BASE_URL}/meetings/${meetingId}`, { title });
    } catch (error) {
        console.error('Error updating meeting title:', error);
    }
}, 500);

const datePickerTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#374151',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4b5563',
    },
    '& .MuiInputBase-input': {
        color: '#e5e7eb',
    },
}));

const StyledMobileDateTimePicker = styled(MobileDateTimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#374151',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4b5563',
    },
    '& .MuiInputBase-input': {
        color: '#e5e7eb',
    },
}));

const Meetings = ({
    isSidebarExpanded,
    onToggleSidebar,
    projects,
    handleSendToProject,
    handleSendToWeeklyPlanner,
    onCreateNewProject,
    selectedMeetingId,
    onSelectMeeting
}) => {
    const [meetings, setMeetings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSummarizing, setIsSummarizing] = useState(false);
    const [isGeneratingTodos, setIsGeneratingTodos] = useState(false);
    const [isMobileView] = useState(window.innerWidth < 640);

    const selectedMeeting = meetings.find(m => m.id === selectedMeetingId);

    const handleDateChange = (newDate) => {
        if (newDate && isValid(newDate)) {
            handleMeetingUpdate({ ...selectedMeeting, date: newDate.toISOString() });
        } else {
            console.warn('Invalid date entered');
        }
    };

    const fetchMeetings = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`${API_BASE_URL}/meetings`);
            setMeetings(response.data);
            if (response.data.length > 0 && !selectedMeetingId) {
                onSelectMeeting(response.data[0].id);
            }
        } catch (error) {
            console.error('Error fetching meetings:', error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedMeetingId, onSelectMeeting]);

    useEffect(() => {
        fetchMeetings();
    }, [fetchMeetings]);

    const handleAddMeeting = useCallback(async () => {
        try {
            const now = new Date();
            const localISOString = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
            const newMeeting = {
                title: "New Meeting",
                date: localISOString.slice(0, 16),
                notes: '',
            };
            const response = await api.post(`${API_BASE_URL}/meetings`, newMeeting);
            setMeetings(prevMeetings => [...prevMeetings, response.data]);
            onSelectMeeting(response.data.id);  // Use onSelectMeeting instead of setSelectedMeetingId
        } catch (error) {
            console.error('Error adding meeting:', error);
        }
    }, [onSelectMeeting]);

    const handleSelectMeeting = useCallback((meeting) => {
        onSelectMeeting(meeting.id);
    }, [onSelectMeeting]);

    const handleMeetingUpdate = useCallback(async (updatedMeeting) => {
        try {
            const response = await api.put(`${API_BASE_URL}/meetings/${updatedMeeting.id}`, updatedMeeting);
            setMeetings(prevMeetings => prevMeetings.map(m =>
                m.id === updatedMeeting.id ? response.data : m
            ));
        } catch (error) {
            console.error('Error updating meeting:', error);
        }
    }, []);

    const handleNotesChange = useCallback((e) => {
        const newNotes = e.target.value;
        if (selectedMeeting) {
            // Update local state immediately for responsiveness
            setMeetings(prevMeetings => prevMeetings.map(meeting =>
                meeting.id === selectedMeeting.id ? { ...meeting, notes: newNotes } : meeting
            ));
            // Debounce the API call
            debouncedUpdateMeetingNotes(selectedMeeting.id, newNotes);
        }
    }, [selectedMeeting]);

    const handleTitleChange = useCallback((e) => {
        const newTitle = e.target.value;
        if (selectedMeeting) {
            // Update local state immediately for responsiveness
            setMeetings(prevMeetings => prevMeetings.map(meeting =>
                meeting.id === selectedMeeting.id ? { ...meeting, title: newTitle } : meeting
            ));
            // Debounce the API call
            debouncedUpdateMeetingTitle(selectedMeeting.id, newTitle);
        }
    }, [selectedMeeting]);

    const handleMeetingDelete = useCallback(async (meetingId) => {
        try {
            await api.delete(`${API_BASE_URL}/meetings/${meetingId}`);
            setMeetings(prevMeetings => prevMeetings.filter(m => m.id !== meetingId));
            if (selectedMeetingId === meetingId) {
                const remainingMeetings = meetings.filter(m => m.id !== meetingId);
                if (remainingMeetings.length > 0) {
                    onSelectMeeting(remainingMeetings[0].id);  // Select the first remaining meeting
                } else {
                    onSelectMeeting(null);  // No meetings left, set selected to null
                }
            }
        } catch (error) {
            console.error('Error deleting meeting:', error);
        }
    }, [selectedMeetingId, meetings, onSelectMeeting]);

    const handleTodosChange = useCallback(async (newTodos) => {
        if (selectedMeeting) {
            try {
                const updatedMeeting = { ...selectedMeeting, todos: newTodos };
                handleMeetingUpdate(updatedMeeting);
            } catch (error) {
                console.error('Error updating todos:', error);
            }
        }
    }, [selectedMeeting, handleMeetingUpdate]);

    const handleDeleteTodo = useCallback(async (todoId) => {
        try {
            await api.delete(`${API_BASE_URL}/meetings/${selectedMeeting.id}/todos/${todoId}`);
            setMeetings(prevMeetings => prevMeetings.map(meeting => {
                if (meeting.id === selectedMeeting.id) {
                    return {
                        ...meeting,
                        todos: meeting.todos.filter(todo => todo.id !== todoId)
                    };
                }
                return meeting;
            }));
        } catch (error) {
            console.error('Error deleting todo:', error);
        }
    }, [selectedMeeting]);

    const handleReorderTodos = useCallback(async (newTodos) => {
        if (selectedMeeting) {
            try {
                const todoIds = newTodos.map(todo => todo.id);
                await api.put(`${API_BASE_URL}/meetings/${selectedMeeting.id}/todos/reorder`, { todos: todoIds });

                // Update local state
                setMeetings(prevMeetings => prevMeetings.map(meeting =>
                    meeting.id === selectedMeeting.id ? { ...meeting, todos: newTodos } : meeting
                ));
            } catch (error) {
                console.error('Error reordering meeting todos:', error);
            }
        }
    }, [selectedMeeting]);

    const handleTaskCompletion = useCallback(async (taskId, updates) => {
        try {
            await api.put(`${API_BASE_URL}/meetings/${selectedMeeting.id}/todos/${taskId}`, updates);
            handleTodosChange(selectedMeeting.todos.map(todo =>
                todo.id === taskId ? { ...todo, ...updates } : todo
            ));
        } catch (error) {
            console.error('Error updating meeting todo:', error);
        }
    }, [selectedMeeting, handleTodosChange]);

    const renderMeetingTodoListActions = useCallback(({ todo, onDelete, onToggleUrgent }) => (
        <MeetingsTodoListActions
            todo={todo}
            onDelete={onDelete}
            onToggleUrgent={onToggleUrgent}
            projects={projects}
            onSendToProject={handleSendToProject}
            onSendToWeeklyPlanner={handleSendToWeeklyPlanner}
            onDeleteTodo={handleDeleteTodo}
            onCreateNewProject={onCreateNewProject}
        />
    ), [projects, handleSendToProject, handleSendToWeeklyPlanner, handleDeleteTodo, onCreateNewProject]);

    const handleSummarize = useCallback(async () => {
        if (selectedMeeting) {
            setIsSummarizing(true);
            try {
                const response = await api.post(`${API_BASE_URL}/meetings/${selectedMeeting.id}/summarize`);
                const summary = response.data.summary;
                handleMeetingUpdate({ ...selectedMeeting, notes: summary });
            } catch (error) {
                console.error('Error summarizing meeting notes:', error);
                // Optionally, show an error message to the user
            } finally {
                setIsSummarizing(false);
            }
        }
    }, [selectedMeeting, handleMeetingUpdate]);

    const handleGenerateTodos = useCallback(async () => {
        if (selectedMeeting) {
            setIsGeneratingTodos(true);
            try {
                const response = await api.post(`${API_BASE_URL}/meetings/${selectedMeeting.id}/generate-todos`);
                const generatedTodos = response.data.todos;
                const updatedTodos = [...(selectedMeeting.todos || []), ...generatedTodos];
                handleMeetingUpdate({ ...selectedMeeting, todos: updatedTodos });
            } catch (error) {
                console.error('Error generating todos:', error);
                // Optionally, show an error message to the user
            } finally {
                setIsGeneratingTodos(false);
            }
        }
    }, [selectedMeeting, handleMeetingUpdate]);

    const handleSendAllTodosToProject = useCallback(async (projectId) => {
        if (selectedMeeting) {
            try {
                // Send the todos with their original order
                const todosWithOrder = selectedMeeting.todos.map((todo, index) => ({
                    id: todo.id,
                    order: index // Use index as order, similar to individual send
                }));

                await api.post(`${API_BASE_URL}/meetings/${selectedMeeting.id}/send-todos-to-project`, {
                    projectId,
                    todos: todosWithOrder
                });

                const updatedMeeting = { ...selectedMeeting, todos: [] };
                handleMeetingUpdate(updatedMeeting);
            } catch (error) {
                console.error('Error sending all todos to project:', error);
            }
        }
    }, [selectedMeeting, handleMeetingUpdate]);

    return (
        <div className="flex h-full relative">
            <MeetingsSidebar
                meetings={meetings}
                selectedMeeting={selectedMeeting}
                onSelectMeeting={handleSelectMeeting}
                onAddMeeting={handleAddMeeting}
                onDeleteMeeting={handleMeetingDelete}
                isExpanded={isSidebarExpanded}
                onToggleSidebar={onToggleSidebar}
            />
            <div
                className={`flex-grow p-6 overflow-y-auto transition-all duration-300 ease-in-out ${isSidebarExpanded ? 'ml-64' : 'ml-16'
                    }`}
            >
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-200">Meetings</h2>
                </div>
                {isLoading ? (
                    <p className="text-gray-400">Loading...</p>
                ) : selectedMeeting ? (
                    <div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium mb-1 text-gray-300" htmlFor="meetingTitle">Title</label>
                            <input
                                type="text"
                                id="meetingTitle"
                                value={selectedMeeting.title}
                                onChange={handleTitleChange}
                                className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Meeting Title"
                                autoComplete="off"
                            />
                        </div>

                        <div className="mb-4 relative">
                            <label className="block text-sm font-medium mb-1 text-gray-300" htmlFor="meetingDate">Date/Time</label>
                            <ThemeProvider theme={datePickerTheme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    {isMobileView ? (
                                        <StyledMobileDateTimePicker
                                            value={parseISO(selectedMeeting.date)}
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            inputFormat="yyyy-MM-dd HH:mm"
                                        />
                                    ) : (
                                        <StyledDateTimePicker
                                            value={parseISO(selectedMeeting.date)}
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            inputFormat="yyyy-MM-dd HH:mm"
                                        />
                                    )}
                                </LocalizationProvider>
                            </ThemeProvider>
                        </div>

                        <div className="mt-4">
                            <label className="block text-sm font-medium mb-1 text-gray-300" htmlFor="meetingNotes">Notes</label>
                            <textarea
                                id="meetingNotes"
                                value={selectedMeeting.notes}
                                onChange={handleNotesChange}
                                className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                rows="7"
                                placeholder="Meeting notes..."
                            />
                        </div>

                        <div className="mt-4 mb-6 flex space-x-4">
                            <button
                                onClick={handleSummarize}
                                disabled={isSummarizing}
                                className={`px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center w-full md:w-auto ${isSummarizing
                                        ? 'bg-gray-500 text-gray-300 cursor-not-allowed'
                                        : 'bg-blue-500 text-white hover:bg-blue-600'
                                    }`}
                            >
                                <span className={`material-icons mr-2 ${isSummarizing ? 'animate-spin' : ''}`}>
                                    {isSummarizing ? 'hourglass_empty' : 'text_snippet'}
                                </span>
                                <span>{isSummarizing ? 'Summarizing...' : 'Summarize'}</span>
                            </button>
                            <button
                                onClick={handleGenerateTodos}
                                disabled={isGeneratingTodos}
                                className={`px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center w-full md:w-auto ${isGeneratingTodos
                                        ? 'bg-gray-500 text-gray-300 cursor-not-allowed'
                                        : 'bg-blue-500 text-white hover:bg-blue-600'
                                    }`}
                            >
                                <span className={`material-icons mr-2 ${isGeneratingTodos ? 'animate-spin' : ''}`}>
                                    {isGeneratingTodos ? 'hourglass_empty' : 'playlist_add_check'}
                                </span>
                                <span>{isGeneratingTodos ? 'Generating...' : 'Generate Todos'}</span>
                            </button>
                        </div>

                        <div className="mt-10">
                            <label className="block text-sm font-medium mb-1 text-gray-300">Todo List:</label>
                            <TodoList
                                todos={selectedMeeting?.todos?.sort((a, b) => a.order - b.order) || []}
                                onTodosChange={handleTodosChange}
                                onReorderTodos={handleReorderTodos}
                                onTaskCompletion={handleTaskCompletion}
                                renderActions={renderMeetingTodoListActions}
                                isMeetingView={true}
                                meetingId={selectedMeeting?.id}
                                projects={projects}
                                onSendAllTodosToProject={handleSendAllTodosToProject}
                                onCreateNewProject={onCreateNewProject}
                            />
                        </div>
                    </div>
                ) : (
                    <p className="text-gray-400">Select a meeting from the sidebar or create a new one.</p>
                )}
            </div>
        </div>
    );
};

export default Meetings;
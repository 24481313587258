export const getStatusColor = (status) => {
    const colors = {
        completed: 'bg-gray-500',
        inProgress: 'bg-green-500',
        waiting: 'bg-orange-500',
        notStarted: 'bg-gray-700'
    };
    return colors[status] || 'bg-blue-500';
};

export const getSelectionStyles = (isSelected) => {
    return isSelected
        ? 'ring-2 ring-white ring-opacity-70 shadow-lg transform scale-105'
        : 'hover:ring-1 hover:ring-gray-400 hover:ring-opacity-50';
};

export const getProjectTypeIcon = (type) => {
    const icons = {
        BEAKER: { icon: 'science', color: 'text-blue-500', iconType: 'material-icons' },
        BLOOD_ROOM: { icon: 'bloodtype', color: 'text-red-500', iconType: 'material-icons' },
        SENSI: { icon: 'pill', color: 'text-blue-500', iconType: 'material-symbols-outlined' },
        VTMS: { icon: 'circle', color: 'text-green-700', iconType: 'material-icons' },
        HR: { icon: 'supervisor_account', color: 'text-pink-500', iconType: 'material-icons' },
    };
    return icons[type] || { icon: 'work', color: 'text-gray-500', iconType: 'material-icons' };
};
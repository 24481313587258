import React, { useState, useMemo, useEffect } from 'react';
import api from '../api'; import { format } from 'date-fns';
import { getSelectionStyles } from './colorUtils';
import EventDialog from './EventDialog';

const WeeklyPlannerSidebar = ({ isExpanded, onToggleSidebar }) => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await api.get('/events');
            setEvents(response.data.map(event => ({
                ...event,
                meetingGenerated: event.meetingGenerated || false
            })));
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const handleAddEvent = async (newEvent) => {
        try {
            const response = await api.post('/events', newEvent);
            setEvents([...events, response.data]);
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Error adding event:', error);
        }
    };

    const handleDeleteClick = (e, eventId) => {
        e.stopPropagation();
        setDeleteConfirmation(eventId);
    };

    const handleDeleteConfirm = async (e, eventId) => {
        e.stopPropagation();
        try {
            await api.delete(`/events/${eventId}`);
            setEvents(events.filter(event => event.id !== eventId));
            setDeleteConfirmation(null);
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    const handleDeleteCancel = (e) => {
        e.stopPropagation();
        setDeleteConfirmation(null);
    };

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    const sortedEvents = useMemo(() => {
        return [...events].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        });
    }, [events, sortOrder]);

    const updateEvent = async (updatedEvent) => {
        try {
            await api.put(`/events/${updatedEvent.id}`, updatedEvent);
        } catch (error) {
            console.error('Error updating event:', error);
            throw error; // Propagate the error to be caught in handleGenerateMeeting
        }
    };

    const handleGenerateMeeting = async (e, event) => {
        e.stopPropagation();
        try {
            const newMeeting = {
                title: event.title,
                date: event.date,
                notes: '',
            };
            const response = await api.post('/meetings', newMeeting);
            console.log('Meeting created:', response.data);

            // Update the event to indicate that a meeting has been generated
            const updatedEvent = { ...event, meetingGenerated: true };

            // Update local state immediately
            setEvents(prevEvents => prevEvents.map(e =>
                e.id === event.id ? updatedEvent : e
            ));

            // Then update the backend
            await updateEvent(updatedEvent);
        } catch (error) {
            console.error('Error creating meeting:', error);
            // Revert the local state change if the backend update fails
            setEvents(prevEvents => prevEvents.map(e =>
                e.id === event.id ? { ...e, meetingGenerated: false } : e
            ));
            // Optionally, show an error message to the user
        }
    };

    return (
        <div className={`absolute top-0 bottom-0 left-0 z-30 bg-gray-700 transition-all duration-300 ease-in-out border-r border-gray-600 flex flex-col
        ${isExpanded ? 'w-64' : 'w-16'}`}>
            <div className="flex items-center justify-between p-4 border-b border-gray-600">
                {isExpanded && (
                    <button
                        onClick={() => setIsDialogOpen(true)}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
                    >
                        <span className="material-icons mr-2">add</span>
                        Add Event
                    </button>
                )}
                <button
                    onClick={onToggleSidebar}
                    className={`p-2 hover:bg-gray-600 transition-colors duration-200 ${isExpanded ? '' : 'flex items-center justify-center w-full h-full'}`}
                >
                    <span className="material-icons">
                        {isExpanded ? 'chevron_left' : 'chevron_right'}
                    </span>
                </button>
            </div>
            <div className="flex-grow overflow-y-auto custom-scrollbar">
                {isExpanded && (
                    <>
                        <div className="flex justify-between items-center px-4 py-2 border-b border-gray-700">
                            <button
                                onClick={toggleSortOrder}
                                className="text-gray-400 hover:text-gray-200 transition-colors duration-200 flex items-center"
                            >
                                <span className="material-icons mr-1 text-lg">
                                    {sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward'}
                                </span>
                                <span className="text-sm">Sort</span>
                            </button>
                        </div>
                        <ul className="space-y-2 p-4">
                            {sortedEvents.map((event) => (
                                <li
                                    key={event.id}
                                    className={`p-2 rounded cursor-pointer transition-all duration-200 flex items-center justify-between
                                ${getSelectionStyles(selectedEvent?.id === event.id)}
                                relative overflow-hidden`}
                                    onClick={() => setSelectedEvent(event)}
                                >
                                    <div className="flex items-center flex-grow min-w-0">
                                        <div className="flex flex-col truncate">
                                            <span className="font-medium text-gray-200 truncate">
                                                {event.title}
                                            </span>
                                            <span className="text-xs text-gray-400">
                                                {format(new Date(event.date), 'MMM d, yyyy h:mm a')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-center ml-2">
                                        {deleteConfirmation === event.id ? (
                                            <div className="flex items-center space-x-1">
                                                <button
                                                    onClick={(e) => handleDeleteConfirm(e, event.id)}
                                                    className="text-white hover:text-gray-200 transition duration-150 ease-in-out"
                                                >
                                                    <span className="material-icons text-lg">check</span>
                                                </button>
                                                <button
                                                    onClick={handleDeleteCancel}
                                                    className="text-white hover:text-gray-200 transition duration-150 ease-in-out"
                                                >
                                                    <span className="material-icons text-lg">close</span>
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                    <button
                                                        onClick={(e) => handleGenerateMeeting(e, event)}
                                                        className="text-white hover:text-gray-200 transition-colors duration-200 mr-2"
                                                    >
                                                        <span className="material-symbols-outlined text-lg">
                                                            {event.meetingGenerated ? 'task_alt' : 'event_upcoming'}
                                                        </span>
                                                    </button>
                                                    <button
                                                        onClick={(e) => handleDeleteClick(e, event.id)}
                                                        className="text-white hover:text-gray-200 transition-colors duration-200"
                                                    >
                                                        <span className="material-symbols-outlined text-lg">delete_outline</span>
                                                    </button>
                                            </>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
            {isDialogOpen && (
                <EventDialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    onSave={handleAddEvent}
                />
            )}
        </div>
    );
};

export default WeeklyPlannerSidebar;

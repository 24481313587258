import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import api from '../api';
import Projects from './Projects';
import WeeklyPlanner from './WeeklyPlanner';
import Meetings from './Meetings';
import RecurringTasks from './RecurringTasks';
import Header from './Header';
import Login from './Login';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

const MainContainer = () => {
    const [headerKey, setHeaderKey] = useState(0);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('projects');
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [lastFetchTime, setLastFetchTime] = useState(null);
    const [weeklyTodos, setWeeklyTodos] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [selectedMeetingId, setSelectedMeetingId] = useState(null);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(() => {
        return window.innerWidth >= 640;
    });
    const [sliderStyle, setSliderStyle] = useState({});
    const tabRefs = useRef({});


    const fetchProjects = useCallback(async (force = false) => {
        const now = Date.now();
        if (!force && lastFetchTime && now - lastFetchTime < 60000) {
            // If not forced and last fetch was less than a minute ago, use cached data
            return;
        }
        try {
            const response = await api.get(`${API_BASE_URL}/projects`, {
                params: { include: 'todos' }
            });
            setProjects(response.data);
            setLastFetchTime(now);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    }, [lastFetchTime, setLastFetchTime]);

    const fetchWeeklyTodos = useCallback(async () => {
        try {
            const response = await api.get(`${API_BASE_URL}/weekly-todos`);
            setWeeklyTodos(response.data);
        } catch (error) {
            console.error('Error fetching weekly todos:', error);
        }
    }, []);

    const fetchMeetings = useCallback(async () => {
        try {
            const response = await api.get(`${API_BASE_URL}/meetings`);
            setMeetings(response.data);
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    }, []);

    const fetchInitialData = useCallback(() => {
        fetchProjects();
        fetchWeeklyTodos();
        fetchMeetings();
    }, [fetchProjects, fetchWeeklyTodos, fetchMeetings]);

    const handleLogin = useCallback((userData) => {
        setUser(userData);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData));
        setIsLoading(false);
        fetchInitialData();
    }, [fetchInitialData]);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUser(null);
    }, []);

    const verifyUser = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            handleLogout();
            setIsLoading(false);
            return;
        }

        try {
            const response = await api.get(`${API_BASE_URL}/auth/verify`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.user) {
                setUser(response.data.user);
                fetchInitialData();
            } else {
                handleLogout();
            }
        } catch (error) {
            console.error('Error verifying user:', error);
            handleLogout();
        }
        setIsLoading(false);
    }, [fetchInitialData, handleLogout]);


    useEffect(() => {
        verifyUser();
    }, [verifyUser]);

    useEffect(() => {
        if (user) {
            setHeaderKey(prevKey => prevKey + 1);
        }
    }, [user]);


    const tabs = useMemo(() => ['Projects', 'Weekly Planner', 'Meetings', 'Recurring Tasks'], []);

    useEffect(() => {
        const activeTabElement = tabRefs.current[activeTab];
        if (activeTabElement) {
            setSliderStyle({
                width: `${activeTabElement.offsetWidth}px`,
                left: `${activeTabElement.offsetLeft}px`,
            });
        }
    }, [activeTab]);

    const syncTodoStatus = useCallback((projectId, todoId, updates) => {
        // Update project todo
        setProjects(prevProjects => prevProjects.map(project => {
            if (project.id === projectId) {
                return {
                    ...project,
                    todos: project.todos.map(todo =>
                        todo.id === todoId ? { ...todo, ...updates } : todo
                    )
                };
            }
            return project;
        }));

        // Update weekly todo
        setWeeklyTodos(prevWeeklyTodos => prevWeeklyTodos.map(todo =>
            (todo.projectId === projectId && todo.originalTodoId === todoId) ? { ...todo, ...updates } : todo
        ));
    }, [setProjects, setWeeklyTodos]);



    const handleSelectProject = useCallback((projectId) => {
        setSelectedProjectId(projectId);
    }, []);

    const updateProjects = useCallback((updatedProjects) => {
        setProjects(updatedProjects);
        setLastFetchTime(Date.now());
    }, []);

    const refreshProjects = useCallback(() => {
        fetchProjects(true);
    }, [fetchProjects]);

    const handleUpdateProject = useCallback(async (projectId, taskId, updates) => {
        try {
            // Sync the todo status
            syncTodoStatus(projectId, taskId, updates);

        } catch (error) {
            console.error('Error updating project:', error);
        }
    }, [syncTodoStatus]);

const handleSendToWeeklyPlanner = useCallback(async (projectTodo, weeklyTodo) => {
    setWeeklyTodos(prevWeeklyTodos => [...prevWeeklyTodos, weeklyTodo]);
    syncTodoStatus(projectTodo.ProjectId, projectTodo.id, { sentToWeeklyPlanner: true });
}, [setWeeklyTodos, syncTodoStatus]);

    const handleToggleSidebar = useCallback(() => {
        setIsSidebarExpanded(prev => !prev);
    }, []);

    const handleSendToProject = useCallback(async (todo, projectId) => {
        try {
            await api.post(`${API_BASE_URL}/projects/${projectId}/todos`, todo);
            setProjects(prevProjects => {
                return prevProjects.map(project => {
                    if (project.id === projectId) {
                        return {
                            ...project,
                            todos: Array.isArray(project.todos)
                                ? [...project.todos, { ...todo, projectId }]
                                : [{ ...todo, projectId }]
                        };
                    }
                    return project;
                });
            });

            await api.delete(`${API_BASE_URL}/weekly-todos/${todo.id}`);
            setWeeklyTodos(prevWeeklyTodos => prevWeeklyTodos.filter(t => t.id !== todo.id));
            return true;
        } catch (error) {
            console.error('Error sending todo to project:', error);
            return false;
        }
    }, []);

    const handleCreateNewProject = useCallback(async (projectName) => {
        try {
            const response = await api.post(`${API_BASE_URL}/projects`, {
                name: projectName,
                status: 'notStarted',
                type: 'SENSI',
                notes: '',
                todos: [],
            });
            const newProject = response.data;
            // Ensure todos is always an array
            newProject.todos = newProject.todos || [];
            setProjects(prevProjects => [...prevProjects, newProject]);
            return newProject.id;
        } catch (error) {
            console.error('Error creating new project:', error);
            throw error;
        }
    }, []);

    const handleSelectMeeting = useCallback((meetingId) => {
        setSelectedMeetingId(meetingId);
    }, []);

    // Add this function to handle sending todos from meetings to projects
    const handleSendFromMeetingToProject = useCallback(async (todo, projectId) => {
        try {
            const response = await api.post(`${API_BASE_URL}/projects/${projectId}/todos`, todo);
            setProjects(prevProjects => {
                return prevProjects.map(project => {
                    if (project.id === projectId) {
                        return {
                            ...project,
                            todos: [...(project.todos || []), response.data]
                        };
                    }
                    return project;
                });
            });
            return true;
        } catch (error) {
            console.error('Error sending todo from meeting to project:', error);
            return false;
        }
    }, [setProjects]);

    // Add this function to handle sending todos from meetings to weekly planner
    const handleSendFromMeetingToWeeklyPlanner = useCallback(async (todo) => {
        try {
            const response = await api.post(`${API_BASE_URL}/weekly-todos`, todo);
            setWeeklyTodos(prevWeeklyTodos => [...prevWeeklyTodos, response.data]);
            return true;
        } catch (error) {
            console.error('Error sending todo from meeting to weekly planner:', error);
            return false;
        }
    }, [setWeeklyTodos]);

    const renderActiveComponent = useCallback(() => {
        switch (activeTab) {
            case 'projects':
                return <Projects
                    isSidebarExpanded={isSidebarExpanded}
                    onToggleSidebar={handleToggleSidebar}
                    projects={projects}
                    updateProjects={updateProjects}
                    onSendToWeeklyPlanner={handleSendToWeeklyPlanner}
                    weeklyTodos={weeklyTodos}
                    onUpdateProject={handleUpdateProject}
                    selectedProjectId={selectedProjectId}
                    onSelectProject={handleSelectProject}
                />;
            case 'weeklyplanner':
                return <WeeklyPlanner
                    isSidebarExpanded={isSidebarExpanded}
                    onToggleSidebar={handleToggleSidebar}
                    projects={projects}
                    onUpdateProject={handleUpdateProject}
                    weeklyTodos={weeklyTodos}
                    setWeeklyTodos={setWeeklyTodos}
                    onSendToProject={handleSendToProject}
                    onCreateNewProject={handleCreateNewProject}
                    refreshProjects={refreshProjects}
                    syncTodoStatus={syncTodoStatus}
                    fetchWeeklyTodos={fetchWeeklyTodos}
                />;
            case 'meetings':
                return <Meetings
                    isSidebarExpanded={isSidebarExpanded}
                    onToggleSidebar={handleToggleSidebar}
                    meetings={meetings}
                    setMeetings={setMeetings}
                    projects={projects}
                    handleSendToProject={handleSendFromMeetingToProject}
                    handleSendToWeeklyPlanner={handleSendFromMeetingToWeeklyPlanner}
                    onCreateNewProject={handleCreateNewProject} 
                    selectedMeetingId={selectedMeetingId}
                    onSelectMeeting={handleSelectMeeting}
                />;
            case 'recurringtasks':
                return <RecurringTasks />;
            default:
                return <Projects
                    projects={projects}
                    setProjects={setProjects}
                    onSendToWeeklyPlanner={handleSendToWeeklyPlanner}
                    isSidebarExpanded={isSidebarExpanded}
                    onToggleSidebar={handleToggleSidebar}
                />;
        }
    }, [activeTab, projects, updateProjects, fetchWeeklyTodos, weeklyTodos, handleUpdateProject, handleSendToWeeklyPlanner, isSidebarExpanded, handleToggleSidebar, handleSendToProject, handleCreateNewProject, refreshProjects, syncTodoStatus, meetings, handleSendFromMeetingToProject, handleSendFromMeetingToWeeklyPlanner, selectedProjectId, handleSelectProject, handleSelectMeeting, selectedMeetingId]);

    if (isLoading) {
        return <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <p className="text-white">Loading...</p>
        </div>;
    }

    if (!user) {
        return <Login onLogin={handleLogin} />;
    }

    return (
        <div className="min-h-screen bg-gray-900 text-gray-200 flex flex-col">
            <Header key={headerKey} user={user} onLogout={handleLogout} />
            <div className="flex-grow flex flex-col">
                <div className="flex justify-center border-b border-gray-700 sticky top-0 bg-gray-800 z-50 relative">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            ref={(el) => (tabRefs.current[tab.toLowerCase().replace(' ', '')] = el)}
                            className={`py-2 px-4 focus:outline-none transition-colors duration-300 ${activeTab === tab.toLowerCase().replace(' ', '')
                                ? 'text-blue-500'
                                : 'hover:text-gray-400'
                                }`}
                            onClick={() => setActiveTab(tab.toLowerCase().replace(' ', ''))}
                        >
                            {tab}
                        </button>
                    ))}
                    <div
                        className="absolute bottom-0 h-0.5 bg-blue-500 transition-all duration-300 ease-in-out"
                        style={sliderStyle}
                    ></div>
                </div>
                <div className="flex-grow relative">
                    <div className="absolute inset-0 overflow-y-auto">
                        {renderActiveComponent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainContainer;
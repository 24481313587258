import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import api from '../api'; import TodoList from './TodoList';
import WeeklyTodoListActions from './WeeklyTodoListActions';
import WeeklyPlannerSidebar from './WeeklyPlannerSidebar';
import RecurringTasksList from './RecurringTasksList';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

//Saving on database operations
const debouncedUpdateNotes = debounce(async (notes) => {
    try {
        await api.put(`${API_BASE_URL}/weekly-notes`, { notes });
    } catch (error) {
        console.error('Error updating weekly notes:', error);
    }
}, 500); //500 ms -> Might consider increasing.

const WeeklyPlanner = ({ isSidebarExpanded,
    onToggleSidebar, projects, onUpdateProject, weeklyTodos, setWeeklyTodos, onSendToProject, onCreateNewProject, refreshProjects, syncTodoStatus, fetchWeeklyTodos }) => {
    const [weeklyNotes, setWeeklyNotes] = useState('');

    useEffect(() => {
        fetchWeeklyNotes();
        fetchWeeklyTodos();
    }, [fetchWeeklyTodos]);

    const sortTodos = useCallback((todos) => {
        return [...todos].sort((a, b) => {
            if (a.completed !== b.completed) {
                return a.completed ? 1 : -1;
            }
            return a.order - b.order;
        });
    }, []);

    const sortedWeeklyTodos = useMemo(() => sortTodos(weeklyTodos), [weeklyTodos, sortTodos]);


    useEffect(() => {
        // Update weeklyTodos with the latest project names and sort them
        setWeeklyTodos(prevTodos => {
            const updatedTodos = prevTodos.map(todo => {
                const project = projects.find(p => p.id === todo.projectId);
                return project ? { ...todo, projectName: project.name } : todo;
            });
            return sortTodos(updatedTodos);
        });
    }, [projects, setWeeklyTodos, sortTodos]);

    const fetchWeeklyNotes = async () => {
        try {
            const response = await api.get(`${API_BASE_URL}/weekly-notes`);
            setWeeklyNotes(response.data.notes);
        } catch (error) {
            console.error('Error fetching weekly notes:', error);
        }
    };

    const handleWeeklyNotesChange = (e) => {
        const newNotes = e.target.value;
        setWeeklyNotes(newNotes);
        debouncedUpdateNotes(newNotes);
    };

    const handleTaskCompletion = useCallback(async (taskId, updates) => {
        const updatedTodo = weeklyTodos.find(todo => todo.id === taskId);
        if (updatedTodo && updatedTodo.projectId) {
            await onUpdateProject(updatedTodo.projectId, taskId, updates);
            // Use syncTodoStatus instead of updating only weekly todos
            syncTodoStatus(updatedTodo.projectId, taskId, updates);
        } else {
            try {
                await api.put(`${API_BASE_URL}/weekly-todos/${taskId}`, updates);
                setWeeklyTodos(prevTodos => prevTodos.map(todo =>
                    todo.id === taskId ? { ...todo, ...updates } : todo
                ));
            } catch (error) {
                console.error('Error updating weekly todo:', error);
            }
        }
    }, [weeklyTodos, onUpdateProject, setWeeklyTodos, syncTodoStatus]);

    const handleSendToProject = useCallback(async (todo, projectId) => {
        console.log('handleSendToProject called with todo:', todo, 'and projectId:', projectId);

        // Optimistically remove the todo from the list
        setWeeklyTodos(prevTodos => prevTodos.filter(t => t.id !== todo.id));

        try {
            let resolvedProjectId = projectId;

            // If projectId is a Promise (new project being created), wait for it to resolve
            if (projectId instanceof Promise) {
                console.log('New project being created, waiting for projectId to resolve...');
                resolvedProjectId = await projectId;
                console.log('Resolved projectId:', resolvedProjectId);
            }

            // Get the current project todos
            const projectResponse = await api.get(`${API_BASE_URL}/projects/${resolvedProjectId}`);
            const projectTodos = projectResponse.data.todos || [];

            // Calculate the new order (highest order + 1)
            const highestOrder = projectTodos.reduce((max, t) => Math.max(max, t.order || 0), 0);
            const newOrder = highestOrder + 1;

            // Create a new todo in the project, excluding the id and including the order
            const { id, ...todoData } = todo;
            const response = await api.post(`${API_BASE_URL}/projects/${resolvedProjectId}/todos`, {
                ...todoData,
                order: newOrder
            });

            console.log('Successfully created new todo in project:', response.data);

            // Delete the weekly todo
            await api.delete(`${API_BASE_URL}/weekly-todos/${todo.id}`);
            console.log(`Successfully deleted todo ${todo.id} from weekly todos`);

            // Refresh the projects list to include the new todo
            await refreshProjects();

        } catch (error) {
            console.error('Error in handleSendToProject:', error);
            // Revert the optimistic update
            setWeeklyTodos(prevTodos => [...prevTodos, todo]);
            alert('Error sending todo to project. Please try again.');
        }
    }, [setWeeklyTodos, refreshProjects]);

    const handleReorderTodos = useCallback(async (newTodos) => {
        try {
            const sortedTodos = sortTodos(newTodos);
            const todoIds = sortedTodos.map(todo => todo.id);
            await api.put(`${API_BASE_URL}/weekly-todos/reorder`, { todos: todoIds });

            // Update local state
            setWeeklyTodos(sortedTodos);
        } catch (error) {
            console.error('Error reordering weekly todos:', error);
        }
    }, [setWeeklyTodos, sortTodos]);

    const renderWeeklyTodoListActions = useCallback(({ todo, onDelete, onToggleUrgent }) => {
        const project = projects.find(p => p.id === todo.projectId); //Passing the entire object for icons
        return (
            <WeeklyTodoListActions
                todo={todo}
                onDelete={onDelete}
                onToggleUrgent={onToggleUrgent}
                projects={projects}
                onSendToProject={handleSendToProject}
                onCreateNewProject={onCreateNewProject}
                project={project}
            />
        );
    }, [projects, handleSendToProject, onCreateNewProject]);

    return (
        <div className="flex h-full relative">
            <WeeklyPlannerSidebar
                projects={projects}
                isExpanded={isSidebarExpanded}
                onToggleSidebar={onToggleSidebar}
            />
            <div
                className={`flex-grow p-6 overflow-y-auto transition-all duration-300 ease-in-out ${isSidebarExpanded ? 'ml-64' : 'ml-16'
                    }`}
            >
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Weekly Planner</h2>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1" htmlFor="weeklyNotes">Weekly Notes</label>
                    <textarea
                        id="weeklyNotes"
                        value={weeklyNotes}
                        onChange={handleWeeklyNotesChange}
                        className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        rows="4"
                        placeholder="Add your weekly notes here..."
                    />
                </div>
                <TodoList
                    todos={sortedWeeklyTodos}
                    onTodosChange={setWeeklyTodos}
                    onReorderTodos={handleReorderTodos}
                    onTaskCompletion={handleTaskCompletion}
                    projects={projects}
                    isWeeklyPlanner={true}
                    renderActions={renderWeeklyTodoListActions}
                />
                <RecurringTasksList />
            </div>
        </div>
    );
};

export default WeeklyPlanner;

import React, { useState, useCallback, useMemo } from 'react';
import api from '../api'; import { getProjectTypeIcon, getStatusColor } from './colorUtils';
import SendToProjectDialog from './SendToProjectDialog';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

const TodoList = React.memo(({
    todos,
    onTodosChange,
    onTaskCompletion,
    projects,
    isWeeklyPlanner,
    weeklyTodos,
    renderActions,
    projectId,
    onReorderTodos,
    isMeetingView,
    meetingId,
    onSendToProject,
    onSendToWeeklyPlanner,
    isTaskInWeeklyPlanner,
    onSendAllTodosToProject,
    onCreateNewProject,
}) => {
    const [newTodo, setNewTodo] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [draggedTodo, setDraggedTodo] = useState(null);
    const [draggedOverTodo, setDraggedOverTodo] = useState(null);
    const [sortByUrgent, setSortByUrgent] = useState(false);
    const [editedText, setEditedText] = useState('');
    const [isSendAllDialogOpen, setIsSendAllDialogOpen] = useState(false);

    const toggleSortByUrgent = useCallback(() => {
        setSortByUrgent(prev => !prev);
    }, []);

    const sortedTodos = useMemo(() => {
        if (!todos || todos.length === 0) return [];
        if (sortByUrgent) {
            return [...todos].sort((a, b) => (b.urgent ? 1 : 0) - (a.urgent ? 1 : 0));
        }
        return todos;
    }, [todos, sortByUrgent]);

    const addTodo = async (e) => {
        e.preventDefault();
        if (!newTodo.trim()) return;
        const newTodoItem = {
            text: newTodo,
            completed: false,
            urgent: false
        };
        try {
            let response;
            if (isWeeklyPlanner) {
                response = await api.post(`${API_BASE_URL}/weekly-todos`, newTodoItem);
            } else if (projectId) {
                response = await api.post(`${API_BASE_URL}/projects/${projectId}/todos`, newTodoItem);
            } else if (isMeetingView && meetingId) {
                response = await api.post(`${API_BASE_URL}/meetings/${meetingId}/todos`, newTodoItem);
            } else {
                throw new Error('Unable to add todo: no project ID or meeting ID available');
            }

            // Add the new todo to the existing todos
            const updatedTodos = [...todos, response.data];
            onTodosChange(updatedTodos);
            setNewTodo('');
        } catch (error) {
            console.error('Error adding todo:', error);
        }
    };


    const reorderTodos = useCallback(async (updatedTodos, toggledTodo) => {
        if (!updatedTodos || updatedTodos.length === 0) return [];
        const uncompletedTodos = updatedTodos.filter(todo => !todo.completed && todo.id !== toggledTodo.id);
        const completedTodos = updatedTodos.filter(todo => todo.completed && todo.id !== toggledTodo.id);
        const reorderedTodos = toggledTodo.completed
            ? [...uncompletedTodos, ...completedTodos, toggledTodo]
            : [...uncompletedTodos, toggledTodo, ...completedTodos];

        try {
            if (isWeeklyPlanner) {
                return reorderedTodos;
            } else if (projectId) {
                const response = await api.put(`${API_BASE_URL}/projects/${projectId}/todos/reorder`, {
                    todos: reorderedTodos.map(t => t.id)
                });
                return response.data;
            } else if (isMeetingView && meetingId) {
                const response = await api.put(`${API_BASE_URL}/meetings/${meetingId}/todos/reorder`, {
                    todos: reorderedTodos.map(t => t.id)
                });
                return response.data;
            } else {
                throw new Error('Unable to reorder todos: no project ID or meeting ID available');
            }
        } catch (error) {
            console.error('Error reordering todos:', error);
            return updatedTodos;
        }
    }, [isWeeklyPlanner, projectId, isMeetingView, meetingId]);

    const toggleTodo = useCallback(async (id) => {
        const todoToUpdate = todos.find(todo => todo.id === id);
        const optimisticUpdate = {
            ...todoToUpdate,
            completed: !todoToUpdate.completed,
            urgent: !todoToUpdate.completed ? false : todoToUpdate.urgent // Set urgent to false if completing
        };

        // Immediately update UI
        onTodosChange(todos.map(todo => todo.id === id ? optimisticUpdate : todo));

        try {
            let response;
            if (isWeeklyPlanner) {
                response = await api.put(`${API_BASE_URL}/weekly-todos/${id}`, optimisticUpdate);
            } else if (projectId) {
                response = await api.put(`${API_BASE_URL}/projects/${projectId}/todos/${id}`, optimisticUpdate);
            } else if (isMeetingView && meetingId) {
                response = await api.put(`${API_BASE_URL}/meetings/${meetingId}/todos/${id}`, optimisticUpdate);
            } else {
                throw new Error('Unable to update todo: no project ID or meeting ID available');
            }
            const updatedTodos = todos.map(todo => todo.id === id ? response.data : todo);
            const reorderedTodos = await reorderTodos(updatedTodos, response.data);
            onTodosChange(reorderedTodos);
            if (onTaskCompletion) {
                onTaskCompletion(id, { completed: response.data.completed, urgent: response.data.urgent });
            }
        } catch (error) {
            // If API call fails, revert the change
            onTodosChange(todos);
            console.error('Error toggling todo:', error);
        }
    }, [todos, isWeeklyPlanner, onTodosChange, onTaskCompletion, reorderTodos, projectId, isMeetingView, meetingId]);

    const toggleUrgent = useCallback(async (id) => {
        try {
            const todoToUpdate = todos.find(todo => todo.id === id);
            const updatedTodo = { ...todoToUpdate, urgent: !todoToUpdate.urgent };
            let response;
            if (isWeeklyPlanner) {
                response = await api.put(`${API_BASE_URL}/weekly-todos/${id}`, updatedTodo);
            } else if (projectId) {
                response = await api.put(`${API_BASE_URL}/projects/${projectId}/todos/${id}`, updatedTodo);
            } else if (isMeetingView && meetingId) {
                response = await api.put(`${API_BASE_URL}/meetings/${meetingId}/todos/${id}`, updatedTodo);
            } else {
                throw new Error('Unable to update todo: no project ID or meeting ID available');
            }
            const updatedTodos = todos.map(todo => todo.id === id ? response.data : todo);
            onTodosChange(updatedTodos);
            if (onTaskCompletion) {
                onTaskCompletion(id, { urgent: response.data.urgent });
            }
        } catch (error) {
            console.error('Error toggling urgent status:', error);
        }
    }, [todos, isWeeklyPlanner, onTodosChange, onTaskCompletion, projectId, isMeetingView, meetingId]);

    const deleteTodo = useCallback(async (id) => {
        try {
            if (isWeeklyPlanner) {
                await api.delete(`${API_BASE_URL}/weekly-todos/${id}`);
            } else if (projectId) {
                await api.delete(`${API_BASE_URL}/projects/${projectId}/todos/${id}`);
            } else if (isMeetingView && meetingId) {
                await api.delete(`${API_BASE_URL}/meetings/${meetingId}/todos/${id}`);
            } else {
                throw new Error('Unable to delete todo: no project ID or meeting ID available');
            }
            const updatedTodos = todos.filter(todo => todo.id !== id);
            onTodosChange(updatedTodos);
        } catch (error) {
            console.error('Error deleting todo:', error);
        }
    }, [todos, isWeeklyPlanner, onTodosChange, projectId, isMeetingView, meetingId]);

    const deleteChecked = useCallback(async () => {
        try {
            const completedTodoIds = todos.filter(todo => todo.completed).map(todo => todo.id);
            let response;
            if (isWeeklyPlanner) {
                response = await api.post(`${API_BASE_URL}/weekly-todos/batch-delete`, { ids: completedTodoIds });
            } else if (projectId) {
                response = await api.post(`${API_BASE_URL}/projects/${projectId}/todos/batch-delete`, { ids: completedTodoIds });
            } else if (isMeetingView && meetingId) {
                response = await api.post(`${API_BASE_URL}/meetings/${meetingId}/todos/batch-delete`, { ids: completedTodoIds });
            } else {
                throw new Error('Unable to delete checked todos: no project ID or meeting ID available');
            }
            const updatedTodos = todos.filter(todo => !todo.completed);
            onTodosChange(updatedTodos);
            console.log(response.data.message);
        } catch (error) {
            console.error('Error deleting checked todos:', error);
        }
    }, [todos, isWeeklyPlanner, projectId, isMeetingView, meetingId, onTodosChange]);

    const startEditing = useCallback((id, text) => {
        setEditingId(id);
        setEditedText(text);
    }, []);

    const finishEditing = useCallback(async (id, newText) => {
        if (newText.trim() === '') return;
        try {
            const todoToUpdate = todos.find(todo => todo.id === id);
            const updatedTodo = { ...todoToUpdate, text: newText };
            let response;
            if (isWeeklyPlanner) {
                response = await api.put(`${API_BASE_URL}/weekly-todos/${id}`, updatedTodo);
            } else if (projectId) {
                response = await api.put(`${API_BASE_URL}/projects/${projectId}/todos/${id}`, updatedTodo);
            } else if (isMeetingView && meetingId) {
                response = await api.put(`${API_BASE_URL}/meetings/${meetingId}/todos/${id}`, updatedTodo);
            } else {
                throw new Error('Unable to update todo: no project ID or meeting ID available');
            }
            const updatedTodos = todos.map(todo => todo.id === id ? response.data : todo);
            onTodosChange(updatedTodos);
            setEditingId(null);
        } catch (error) {
            console.error('Error updating todo text:', error);
            // TODO: Add user-friendly error handling
        }
    }, [todos, isWeeklyPlanner, onTodosChange, projectId, isMeetingView, meetingId]);

    const handleDragStart = useCallback((e, todo) => {
        setDraggedTodo(todo);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', todo.id);
        e.target.style.opacity = '0.5';
    }, []);

    const handleDragOver = useCallback((e, todo) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setDraggedOverTodo(todo);
    }, []);

    const handleDrop = useCallback(async (e, targetTodo) => {
        e.preventDefault();
        if (!draggedTodo || draggedTodo.id === targetTodo.id) return;

        const newTodos = [...todos];
        const draggedIndex = newTodos.findIndex(todo => todo.id === draggedTodo.id);
        const targetIndex = newTodos.findIndex(todo => todo.id === targetTodo.id);

        newTodos.splice(draggedIndex, 1);
        newTodos.splice(targetIndex, 0, draggedTodo);

        // Update the order field for each todo
        const updatedTodos = newTodos.map((todo, index) => ({
            ...todo,
            order: index
        }));

        if (typeof onReorderTodos === 'function') {
            onReorderTodos(updatedTodos);
        } else {
            onTodosChange(updatedTodos);
        }

        setDraggedTodo(null);
        setDraggedOverTodo(null);
    }, [draggedTodo, todos, onReorderTodos, onTodosChange]);

    const handleDragEnd = useCallback((e) => {
        e.target.style.opacity = '1';
        setDraggedTodo(null);
        setDraggedOverTodo(null);
    }, []);

    const getStyles = useCallback((todo) => {
        if (draggedTodo && draggedTodo.id === todo.id) {
            return "opacity-50";
        }
        if (draggedOverTodo && draggedOverTodo.id === todo.id) {
            const draggedIndex = todos.findIndex(t => t.id === draggedTodo.id);
            const targetIndex = todos.findIndex(t => t.id === todo.id);
            if (draggedIndex < targetIndex) {
                return "border-b-2 border-blue-500";
            } else {
                return "border-t-2 border-blue-500";
            }
        }
        return "";
    }, [draggedTodo, draggedOverTodo, todos]);

    const handleSendAllTodosToProject = async (projectId) => {
        if (onSendAllTodosToProject) {
            await onSendAllTodosToProject(projectId);
            setIsSendAllDialogOpen(false);
        }
    };

    const renderTodoItem = (todo) => {
        const project = projects.find(p => p.id === todo.projectId);
        const projectIcon = project ? getProjectTypeIcon(project.type) : null;
        const statusColor = project ? getStatusColor(project.status) : 'bg-gray-600';

        const projectPrefix = todo.projectName ? (
            <span className={`mr-2 px-2 py-1 ${statusColor} text-xs font-semibold rounded inline-flex items-center border border-white border-opacity-30`}>
                {projectIcon && (
                    <span className={`${projectIcon.iconType} mr-1 ${projectIcon.color} text-base`}>
                        {projectIcon.icon}
                    </span>
                )}
                <span className="relative top-px">{todo.projectName.slice(0, 24)}</span>
            </span>
        ) : null;

        return (
            <li
                key={todo.id}
                className={`flex flex-wrap items-center bg-gray-700 p-2 rounded ${getStyles(todo)} ${todo.urgent ? 'bg-red-200 bg-opacity-20' : ''}`}
                draggable
                onDragStart={(e) => handleDragStart(e, todo)}
                onDragOver={(e) => handleDragOver(e, todo)}
                onDrop={(e) => handleDrop(e, todo)}
                onDragEnd={handleDragEnd}
            >
                <span className="material-icons text-gray-400 cursor-move mr-2 text-xl md:text-2xl">
                    drag_indicator
                </span>
                <div className="flex items-center h-6 mr-2">
                    <input
                        id={`todo-${todo.id}`}
                        type="checkbox"
                        checked={todo.completed}
                        onChange={() => toggleTodo(todo.id)}
                        className="hidden"
                    />
                    <label
                        htmlFor={`todo-${todo.id}`}
                        className="flex items-center justify-center w-6 h-6 border-2 border-gray-400 rounded-md cursor-pointer hover:border-blue-500 transition-colors duration-200"
                    >
                        {todo.completed && (
                            <svg
                                className="w-4 h-4 text-blue-500 pointer-events-none"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                ></path>
                            </svg>
                        )}
                    </label>
                </div>
                {isWeeklyPlanner && projectPrefix}
                <div className="flex-grow">
                    {editingId === todo.id ? (
                        <input
                            type="text"
                            value={editedText}
                            onChange={(e) => setEditedText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    finishEditing(todo.id, editedText);
                                } else if (e.key === 'Escape') {
                                    setEditingId(null);
                                }
                            }}
                            onBlur={() => finishEditing(todo.id, editedText)}
                            className="w-full bg-gray-600 text-white p-1 rounded text-sm md:text-base"
                            autoFocus
                        />
                    ) : (
                        <span
                            className={`${todo.completed ? 'line-through text-gray-500' : 'text-white'} cursor-pointer text-sm md:text-base`}
                            onClick={() => startEditing(todo.id, todo.text)}
                        >
                            {todo.text}
                        </span>
                    )}
                </div>
                {renderActions({
                    todo,
                    onDelete: deleteTodo,
                    onToggleUrgent: toggleUrgent,
                })}
            </li>
        );
    };

    return (
        <div className="space-y-4">
            <form onSubmit={addTodo} className="flex flex-col md:flex-row mb-4">
                <input
                    type="text"
                    value={newTodo}
                    onChange={(e) => setNewTodo(e.target.value)}
                    placeholder="Add a new task"
                    className="flex-grow px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-t-md md:rounded-l-md md:rounded-tr-none focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
                />
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-b-md md:rounded-r-md md:rounded-bl-none hover:bg-blue-600 transition-colors duration-200 text-sm md:text-base"
                >
                    Add
                </button>
            </form>
            <div className="flex justify-between items-center mb-4">
                <div className="flex space-x-2">
                    <button
                        onClick={toggleSortByUrgent}
                        className={`px-2 py-1 rounded-md transition-colors duration-200 flex items-center text-sm ${sortByUrgent
                            ? 'bg-red-500 text-white hover:bg-red-600'
                            : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
                            }`}
                        title="Sort by Urgent"
                    >
                        <span className="material-icons mr-1 text-lg">sort</span>
                        !
                    </button>
                    {/* Send All to Project Button*/}
                    {isMeetingView && (
                        <button
                            onClick={() => setIsSendAllDialogOpen(true)}
                            className="px-3 py-1.5 bg-blue-500 text-white text-sm rounded hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
                            title="Send all to Project"
                        >
                            <span className="material-icons text-lg">drive_folder_upload</span>
                        </button>
                    )}
                </div>
                {todos.some(todo => todo.completed) && (
                    <button
                        onClick={deleteChecked}
                        className="px-3 py-2 bg-red-500 text-white text-sm md:text-base rounded hover:bg-red-600 transition-colors duration-200 flex items-center"
                    >
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                        </svg>
                        Delete Checked
                    </button>
                )}
            </div>
            {todos && todos.length > 0 ? (
                <ul className="space-y-2">
                    {sortedTodos.map(todo => renderTodoItem(todo))}
                </ul>
            ) : (
                <p className="text-gray-400">No todos available. Add a new todo to get started!</p>
            )}

            {isMeetingView && (
                <SendToProjectDialog
                    open={isSendAllDialogOpen}
                    onClose={() => setIsSendAllDialogOpen(false)}
                    projects={projects}
                    onSendToProject={handleSendAllTodosToProject}
                    onCreateNewProject={onCreateNewProject}
                    isSendAll={true}
                />
            )}
        </div>
    );
});

export default TodoList;
import React, { useState, useEffect, useMemo } from 'react';
import api from '../api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

const RecurringTasksList = () => {
    const [recurringTasks, setRecurringTasks] = useState([]);

    useEffect(() => {
        fetchRecurringTasks();
    }, []);

    const fetchRecurringTasks = async () => {
        try {
            const response = await api.get(`${API_BASE_URL}/recurring-tasks`);
            const filteredTasks = response.data.filter(task => {
                const currentDate = new Date();
                const dueDate = new Date(task.nextDue);
                const preparationDate = new Date(dueDate.getTime() - task.preparationTime * 24 * 60 * 60 * 1000);
                return currentDate >= preparationDate;
            });
            setRecurringTasks(filteredTasks);
        } catch (error) {
            console.error('Error fetching recurring tasks:', error);
        }
    };

    const handleMarkAsDone = async (taskId) => {
        try {
            const task = recurringTasks.find(t => t.id === taskId);
            const lastDone = new Date().toISOString();
            const nextDue = calculateNextDueDate(lastDone, task.frequency);

            await api.put(`${API_BASE_URL}/recurring-tasks/${taskId}`, {
                ...task,
                lastDone,
                nextDue
            });

            // Remove the task from the list
            setRecurringTasks(recurringTasks.filter(t => t.id !== taskId));
        } catch (error) {
            console.error('Error marking recurring task as done:', error);
        }
    };

    const calculateNextDueDate = (lastDone, frequency) => {
        const date = new Date(lastDone);
        switch (frequency) {
            case 'weekly':
                date.setDate(date.getDate() + 7);
                break;
            case 'monthly':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'biannual':
                date.setMonth(date.getMonth() + 6);
                break;
            case 'annual':
                date.setFullYear(date.getFullYear() + 1);
                break;
            default:
                break;
        }
        return date.toISOString();
    };

    const getDueRange = (task) => {
        const dueDate = new Date(task.nextDue);
        const rangeEnd = new Date(dueDate.getTime() + task.range * 24 * 60 * 60 * 1000);
        return `${dueDate.toLocaleDateString()} - ${rangeEnd.toLocaleDateString()}`;
    };

    const isOverdue = (task) => {
        const currentDate = new Date();
        const rangeEnd = new Date(new Date(task.nextDue).getTime() + task.range * 24 * 60 * 60 * 1000);
        return currentDate > rangeEnd;
    };

    const sortedRecurringTasks = useMemo(() => {
        return [...recurringTasks].sort((a, b) => new Date(a.nextDue) - new Date(b.nextDue));
    }, [recurringTasks]);

    return (
        <div className="mt-8">
            <h3 className="text-xl font-bold mb-4">Recurring Tasks</h3>
            {sortedRecurringTasks.length > 0 ? (
                <ul className="space-y-2">
                    {sortedRecurringTasks.map(task => (
                        <li key={task.id} className="flex items-center bg-gray-700 p-2 rounded">
                            <div className="flex items-center h-6 mr-2">
                                <input
                                    id={`task-${task.id}`}
                                    type="checkbox"
                                    onChange={() => handleMarkAsDone(task.id)}
                                    className="hidden"
                                />
                                <label
                                    htmlFor={`task-${task.id}`}
                                    className="flex items-center justify-center w-6 h-6 border-2 border-gray-400 rounded-md cursor-pointer hover:border-blue-500 transition-colors duration-200"
                                >
                                    {/* Checkbox will be empty as tasks are not marked as completed in this view */}
                                </label>
                            </div>
                            <span className="flex-grow">{task.title}</span>
                            <span className={`ml-2 ${isOverdue(task) ? 'text-red-500' : 'text-gray-400'}`}>
                                Due: {getDueRange(task)}
                            </span>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-gray-400">No recurring tasks due soon.</p>
            )}
        </div>
    );
};

export default RecurringTasksList;
import React from 'react';

const StatusSelector = ({ status, onStatusChange }) => {
    const statusOptions = [
        { value: 'completed', label: 'Completed', color: 'bg-gray-500 hover:bg-gray-600', icon: 'check_circle' },
        { value: 'inProgress', label: 'In Progress', color: 'bg-green-500 hover:bg-green-600', icon: 'autorenew' },
        { value: 'waiting', label: 'Waiting', color: 'bg-orange-500 hover:bg-orange-600', icon: 'hourglass_empty' },
        { value: 'notStarted', label: 'Not Started', color: 'bg-black hover:bg-gray-800', icon: 'not_started' },
    ];

    return (
        <div className="mb-6 overflow-x-auto">
            <div className="inline-flex rounded-md shadow-sm" role="group">
                {statusOptions.map((option, index) => (
                    <button
                        key={option.value}
                        type="button"
                        onClick={() => onStatusChange(option.value)}
                        className={`
                            px-3 py-2 text-sm font-medium flex items-center whitespace-nowrap
                            ${index === 0 ? 'rounded-l-lg' : ''}
                            ${index === statusOptions.length - 1 ? 'rounded-r-lg' : ''}
                            ${status === option.value
                                ? `${option.color} text-white`
                                : 'bg-blue-500 text-white hover:bg-blue-600'
                            }
                            border border-blue-600
                            focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-white
                            transition-colors duration-200
                        `}
                    >
                        <span className="material-icons mr-1 text-sm">{option.icon}</span>
                        <span className="hidden sm:inline">{option.label}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default StatusSelector;
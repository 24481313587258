import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { isValid } from 'date-fns'; 

const datePickerTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#374151',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4b5563',
    },
    '& .MuiInputBase-input': {
        color: '#e5e7eb',
    },
}));

const StyledMobileDateTimePicker = styled(MobileDateTimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#374151',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4b5563',
    },
    '& .MuiInputBase-input': {
        color: '#e5e7eb',
    },
}));

const EventDialog = ({ open, onClose, onSave }) => {
    const [title, setTitle] = useState('');
    const [date, setDate] = useState(new Date());
    const dialogRef = useRef(null);
    const [isMobileView] = useState(window.innerWidth < 640);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const handleSave = useCallback(() => {
        if (title && date) {
            onSave({
                title,
                date: date.toISOString()
            });
            onClose();
        }
    }, [title, date, onSave, onClose]);

    const handleDateChange = (newDate) => {
        if (newDate && isValid(newDate)) {
            setDate(newDate);
        } else {
            console.warn('Invalid date entered');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target) && !isDatePickerOpen) {
                onClose();
            }
        };

        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && title.trim()) {
                handleSave();
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [open, onClose, title, handleSave, isDatePickerOpen]);

    if (!open) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div 
                ref={dialogRef} 
                className="bg-gray-800 rounded-lg p-6 w-full max-w-md"
            >
                <h2 className="text-xl font-bold mb-4 text-gray-200">Add New Event</h2>
                <div className="mb-4">
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Event Title"
                        className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <ThemeProvider theme={datePickerTheme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {isMobileView ? (
                                <StyledMobileDateTimePicker
                                    value={date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    inputFormat="yyyy-MM-dd HH:mm"
                                    onOpen={() => setIsDatePickerOpen(true)}
                                    onClose={() => setIsDatePickerOpen(false)}
                                />
                            ) : (
                                <StyledDateTimePicker
                                    value={date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    inputFormat="yyyy-MM-dd HH:mm"
                                    onOpen={() => setIsDatePickerOpen(true)}
                                    onClose={() => setIsDatePickerOpen(false)}
                                />
                            )}
                        </LocalizationProvider>
                    </ThemeProvider>
                </div>
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded hover:bg-gray-700 transition-colors duration-200"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        disabled={!title.trim()}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Save Event
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EventDialog;
import React, { useState, useCallback } from 'react';
import TodoListActions from './TodoListActions';
import SendToProjectDialog from './SendToProjectDialog';

const MeetingsTodoListActions = ({
    todo,
    onDelete,
    onToggleUrgent,
    projects,
    onSendToProject,
    onSendToWeeklyPlanner,
    onDeleteTodo,
    onCreateNewProject
}) => {
    const [isProjectDialogOpen, setIsProjectDialogOpen] = useState(false);
    const [sendingToWeeklyPlanner, setSendingToWeeklyPlanner] = useState(false);

    const handleOpenProjectDialog = () => setIsProjectDialogOpen(true);
    const handleCloseProjectDialog = () => setIsProjectDialogOpen(false);

    const handleSendToProject = async (todo, projectId) => {
        const success = await onSendToProject(todo, projectId);
        if (success) {
            onDeleteTodo(todo.id);
        }
        handleCloseProjectDialog();
    };

    const handleSendToWeeklyPlanner = useCallback(async () => {
        setSendingToWeeklyPlanner(true);
        try {
            const success = await onSendToWeeklyPlanner(todo);
            if (success) {
                onDeleteTodo(todo.id);
            }
        } catch (error) {
            console.error('Error sending todo to weekly planner:', error);
            // Optionally, add user-friendly error handling here
        } finally {
            setSendingToWeeklyPlanner(false);
        }
    }, [todo, onSendToWeeklyPlanner, onDeleteTodo]);

    return (
        <div className="flex items-center space-x-1 md:space-x-2 ml-2 mt-2 md:mt-0">
            <button
                onClick={handleOpenProjectDialog}
                className="text-white hover:text-blue-300 transition-colors duration-200 p-1 md:p-1"
                title="Send to Project"
            >
                <span className="material-icons text-xl md:text-2xl">create_new_folder</span>
            </button>
            <SendToProjectDialog
                open={isProjectDialogOpen}
                onClose={handleCloseProjectDialog}
                projects={projects}
                onSendToProject={handleSendToProject}
                todo={todo}
                onCreateNewProject={onCreateNewProject} 
            />
            <button
                onClick={handleSendToWeeklyPlanner}
                disabled={sendingToWeeklyPlanner}
                className={`transition-colors duration-200 p-1 md:p-2 ${sendingToWeeklyPlanner
                        ? 'text-gray-500 cursor-not-allowed'
                        : 'text-white hover:text-blue-300'
                    }`}
                title="Send to Weekly Planner"
            >
                {sendingToWeeklyPlanner ? (
                    <span className="material-icons text-white text-xl md:text-2xl">hourglass_empty</span>
                ) : (
                    <span className="material-icons text-xl md:text-2xl">event_note</span>
                )}
            </button>
            <TodoListActions todo={todo} onDelete={onDelete} onToggleUrgent={onToggleUrgent} />
        </div>
    );
};

export default MeetingsTodoListActions;
// ProjectTypeSelector.js
import React, { useState, useRef, useEffect } from 'react';

export const PROJECT_TYPES = {
    UNCATEGORIZED: { name: 'Uncategorized', icon: 'pets', color: 'text-white', iconType: 'material-icons' },
    BEAKER: { name: 'Beaker', icon: 'science', color: 'text-blue-500', iconType: 'material-icons' },
    BLOOD_ROOM: { name: 'Blood Room', icon: 'bloodtype', color: 'text-red-500', iconType: 'material-icons' },
    SENSI: { name: 'Sensi', icon: 'pill', color: 'text-blue-500', iconType: 'material-symbols-outlined' },
    VTMS: { name: 'VTMS', icon: 'circle', color: 'text-green-700', iconType: 'material-icons' },
    HR: { name: 'HR', icon: 'supervisor_account', color: 'text-pink-500', iconType: 'material-icons' },
};

const Option = ({ value, name, selected, onClick }) => {
    const { icon, color, iconType } = PROJECT_TYPES[value];
    return (
        <div
            className={`flex items-center px-4 py-2 cursor-pointer ${selected ? 'bg-blue-600' : 'hover:bg-gray-600'
                }`}
            onClick={() => onClick(value)}
        >
            <i className={`${iconType} ${color} mr-2`}>{icon}</i>
            <span>{name}</span>
        </div>
    );
};

const ProjectSelector = ({ selectedType, onTypeChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (value) => {
        onTypeChange(value);
        setIsOpen(false);
    };

    // Ensure selectedType is a valid key in PROJECT_TYPES
    const validSelectedType = PROJECT_TYPES.hasOwnProperty(selectedType) ? selectedType : Object.keys(PROJECT_TYPES)[0];
    const { name, icon, color, iconType } = PROJECT_TYPES[validSelectedType];

    return (
        <div className="mb-4 relative" ref={dropdownRef}>
            <label className="block text-sm font-medium mb-1" htmlFor="projectType">
                Project Type
            </label>
            <button
                id="projectType"
                className="w-full px-3 py-2 bg-gray-700 text-gray-200 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex items-center">
                    <i className={`${iconType} ${color} mr-2`}>{icon}</i>
                    <span>{name}</span>
                </div>
                <i className="material-icons">arrow_drop_down</i>
            </button>
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-gray-700 border border-gray-600 rounded-md shadow-lg">
                    {Object.entries(PROJECT_TYPES).map(([key, { name }]) => (
                        <Option
                            key={key}
                            value={key}
                            name={name}
                            selected={key === validSelectedType}
                            onClick={handleSelect}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ProjectSelector;
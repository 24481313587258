import React from 'react';

const TodoListActions = ({ todo, onDelete, onToggleUrgent }) => {
    return (
        <>
            <button
                onClick={() => onToggleUrgent(todo.id)}
                className={`${todo.urgent ? 'text-red-300' : 'text-gray-400'} hover:text-red-500 transition-colors duration-200 p-1 md:p-0 mx-0.1`}
                title={todo.urgent ? "Unmark as Urgent" : "Mark as Urgent"}
            >
                <span className="material-icons text-xl md:text-2xl">priority_high</span>
            </button>
            <button
                onClick={() => onDelete(todo.id)}
                className="text-white hover:text-red-300 transition-colors duration-200 p-1 md:p-1.5 mx-0.5"
                title="Delete"
            >
                <span className="material-icons text-xl md:text-2xl">delete</span>
            </button>
        </>
    );
};

export default TodoListActions;

import React, { useState, useCallback } from 'react';
import { getStatusColor, getSelectionStyles } from './colorUtils';
import { PROJECT_TYPES } from './ProjectTypeSelector';

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + '...';
};

const ProjectSidebar = ({
    projects,
    selectedProject,
    onSelectProject,
    onAddProject,
    onDeleteProject,
    isExpanded,
    onToggleSidebar,
    onUpdateProjectsOrder
}) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [draggedProject, setDraggedProject] = useState(null);
    const [draggedOverProject, setDraggedOverProject] = useState(null);

    const handleDragStart = useCallback((e, project) => {
        setDraggedProject(project);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', project.id);
        e.target.style.opacity = '0.5';
    }, []);

    const handleDragOver = useCallback((e, project) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setDraggedOverProject(project);
    }, []);

    const handleDrop = useCallback((e, targetProject) => {
        e.preventDefault();
        if (!draggedProject || draggedProject.id === targetProject.id) return;

        const updatedProjects = [...projects];
        const draggedIndex = updatedProjects.findIndex(p => p.id === draggedProject.id);
        const targetIndex = updatedProjects.findIndex(p => p.id === targetProject.id);

        const [removedProject] = updatedProjects.splice(draggedIndex, 1);
        updatedProjects.splice(targetIndex, 0, removedProject);

        // Update the order property for each project
        const reorderedProjects = updatedProjects.map((project, index) => ({
            ...project,
            order: index
        }));

        // Call the parent component's update function with the reordered projects
        onUpdateProjectsOrder(reorderedProjects);
        setDraggedProject(null);
        setDraggedOverProject(null);
    }, [draggedProject, projects, onUpdateProjectsOrder]);

    const handleDragEnd = useCallback((e) => {
        e.target.style.opacity = '1';
        setDraggedProject(null);
        setDraggedOverProject(null);
    }, []);

    const getDragIndicatorStyles = useCallback((project) => {
        if (!draggedOverProject || !draggedProject || draggedProject.id === project.id) return '';

        if (draggedOverProject.id === project.id) {
            const draggedIndex = projects.findIndex(p => p.id === draggedProject.id);
            const targetIndex = projects.findIndex(p => p.id === project.id);

            if (draggedIndex < targetIndex) {
                return 'border-b-2 border-blue-500';
            } else {
                return 'border-t-2 border-blue-500';
            }
        }

        return '';
    }, [draggedOverProject, draggedProject, projects]);

    return (
        <div className={`absolute top-0 bottom-0 left-0 z-30 bg-gray-700 transition-all duration-300 ease-in-out border-r border-gray-600 flex flex-col
            ${isExpanded ? 'w-64' : 'w-16'}`}>
            <div className="flex items-center justify-between p-4 border-b border-gray-600">
                {isExpanded && (
                    <button
                        onClick={onAddProject}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
                    >
                        <span className="material-icons mr-2">add</span>
                        Add Project
                    </button>
                )}
                <button
                    onClick={onToggleSidebar}
                    className={`p-2 hover:bg-gray-600 transition-colors duration-200 ${isExpanded ? '' : 'flex items-center justify-center w-full h-full'}`}
                >
                    <span className="material-icons">
                        {isExpanded ? 'chevron_left' : 'chevron_right'}
                    </span>
                </button>
            </div>
            <div className="flex-grow overflow-y-auto custom-scrollbar">
                {isExpanded && (
                    <ul className="space-y-2 p-4">
                        {projects.map((project) => (
                            <li
                                key={project.id}
                                className={`p-2 rounded cursor-pointer transition-all duration-200 flex items-center justify-between
                                ${getStatusColor(project.status)}
                                ${getSelectionStyles(selectedProject?.id === project.id)}
                                ${getDragIndicatorStyles(project)}
                                relative overflow-hidden`}
                                onClick={() => onSelectProject(project)}
                                draggable
                                onDragStart={(e) => handleDragStart(e, project)}
                                onDragOver={(e) => handleDragOver(e, project)}
                                onDrop={(e) => handleDrop(e, project)}
                                onDragEnd={handleDragEnd}
                            >
                                <div className="flex items-center flex-grow min-w-0">
                                    <span className={`${PROJECT_TYPES[project.type].iconType} mr-2 relative z-10 ${PROJECT_TYPES[project.type].color}`}>
                                        {PROJECT_TYPES[project.type].icon}
                                    </span>
                                    <span className="relative z-10 truncate">
                                        {truncateText(project.name, 23)}
                                    </span>
                                </div>
                                <div className="flex items-center ml-2">
                                    {deleteConfirmation === project.id ? (
                                        <div className="flex items-center space-x-1">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDeleteProject(project.id);
                                                    setDeleteConfirmation(null);
                                                }}
                                                className="text-white hover:text-gray-200 transition duration-150 ease-in-out"
                                            >
                                                <span className="material-icons text-sm">check</span>
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteConfirmation(null);
                                                }}
                                                className="text-white hover:text-gray-200 transition duration-150 ease-in-out"
                                            >
                                                <span className="material-icons text-sm">close</span>
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDeleteConfirmation(project.id);
                                            }}
                                            className="text-white hover:text-gray-200 transition-colors duration-200"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                            </svg>
                                        </button>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ProjectSidebar;

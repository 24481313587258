import React, { useState, useCallback } from 'react';
import TodoListActions from './TodoListActions';

// In ProjectTodoListActions.js

const ProjectTodoListActions = ({ todo, onDelete, onToggleUrgent, onSendToWeeklyPlanner, isTaskInWeeklyPlanner }) => {
    const [sendingToWeeklyPlanner, setSendingToWeeklyPlanner] = useState(null);

    const handleSendToWeeklyPlanner = useCallback(() => {
        onSendToWeeklyPlanner(todo).then((result) => {
            if (result) {
                setSendingToWeeklyPlanner(todo.id);
                setTimeout(() => {
                    setSendingToWeeklyPlanner(null);
                }, 1000);
            }
        });
    }, [todo, onSendToWeeklyPlanner]);

    return (
        <div className="flex items-center space-x-1 md:space-x-2 ml-2 mt-2 md:mt-0">
            <button
                onClick={handleSendToWeeklyPlanner}
                title={isTaskInWeeklyPlanner(todo.id) ? "Sent to Weekly Planner" : "Send to Weekly Planner"}
                className={`transition-colors duration-200 p-1 md:p-2 ${isTaskInWeeklyPlanner(todo.id)
                        ? 'text-gray-500 cursor-not-allowed'
                        : 'text-white hover:text-blue-300'
                    }`}
                disabled={isTaskInWeeklyPlanner(todo.id)}
            >
                {sendingToWeeklyPlanner === todo.id ? (
                    <span className="material-icons text-white text-xl md:text-2xl">check_circle</span>
                ) : isTaskInWeeklyPlanner(todo.id) ? (
                    <span className="material-icons text-xl md:text-2xl">event_available</span>
                ) : (
                    <span className="material-icons text-xl md:text-2xl">event_note</span>
                )}
            </button>
            <TodoListActions todo={todo} onDelete={onDelete} onToggleUrgent={onToggleUrgent} />
        </div>
    );
};

export default ProjectTodoListActions;
import React, { useState, useEffect } from 'react';
import api from '../api';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { isValid } from 'date-fns';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/api';

const datePickerTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#374151',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4b5563',
    },
    '& .MuiInputBase-input': {
        color: '#e5e7eb',
    },
}));

const StyledMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#374151',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4b5563',
    },
    '& .MuiInputBase-input': {
        color: '#e5e7eb',
    },
}));

const RecurringTasks = () => {
    const [recurringTasks, setRecurringTasks] = useState([]);
    const [newTask, setNewTask] = useState({
        title: '',
        frequency: 'weekly',
        preparationTime: 40,
        range: 30,
        nextDue: new Date(),
        lastDone: null
    });
    const [editingTask, setEditingTask] = useState(null);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [isMobileView] = useState(window.innerWidth < 640);

    useEffect(() => {
        fetchRecurringTasks();
    }, []);

    const fetchRecurringTasks = async () => {
        try {
            const response = await api.get(`${API_BASE_URL}/recurring-tasks`);
            setRecurringTasks(response.data);
        } catch (error) {
            console.error('Error fetching recurring tasks:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewTask({ ...newTask, [e.target.name]: e.target.value });
    };

    const handleDateChange = (date, field) => {
        if (date && isValid(date)) {
            // Set the time to noon (12:00:00) for consistency
            const newDate = new Date(date);
            newDate.setHours(12, 0, 0, 0);
            setNewTask({ ...newTask, [field]: newDate });
        } else {
            console.warn('Invalid date entered');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Convert nextDue to ISO string format
            const taskToSubmit = {
                ...newTask,
                nextDue: newTask.nextDue.toISOString(),
                // Convert preparationTime and range to numbers
                preparationTime: parseInt(newTask.preparationTime, 10),
                range: parseInt(newTask.range, 10)
            };
            const response = await api.post(`${API_BASE_URL}/recurring-tasks`, taskToSubmit);
            setRecurringTasks([...recurringTasks, response.data]);
            setNewTask({
                title: '',
                frequency: 'weekly',
                preparationTime: 40,
                range: 30,
                nextDue: new Date(),
                lastDone: null
            });
        } catch (error) {
            console.error('Error creating recurring task:', error);
            // Add more detailed error logging
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
            }
        }
    };

    const handleUpdate = async (id, updatedTask) => {
        try {
            let taskToUpdate = { ...updatedTask };

            // If the task is being marked as done, recalculate the nextDue date
            if (updatedTask.lastDone) {
                const newLastDone = new Date(updatedTask.lastDone);
                taskToUpdate.nextDue = calculateNextDueDate(newLastDone, updatedTask.frequency);
            }

            // Create a new object with the updated fields
            taskToUpdate = {
                ...taskToUpdate,
                // Convert nextDue to ISO string only if it's a Date object
                nextDue: taskToUpdate.nextDue instanceof Date
                    ? taskToUpdate.nextDue.toISOString()
                    : taskToUpdate.nextDue,
                // Convert lastDone to ISO string
                lastDone: taskToUpdate.lastDone ? new Date(taskToUpdate.lastDone).toISOString() : null,
                // Convert preparationTime and range to numbers
                preparationTime: parseInt(taskToUpdate.preparationTime, 10),
                range: parseInt(taskToUpdate.range, 10)
            };

            const response = await api.put(`${API_BASE_URL}/recurring-tasks/${id}`, taskToUpdate);
            setRecurringTasks(recurringTasks.map(task =>
                task.id === id ? response.data : task
            ));
        } catch (error) {
            console.error('Error updating recurring task:', error);
            // Add more detailed error logging
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
            }
        }
    };


    const handleDelete = async (id) => {
        try {
            await api.delete(`${API_BASE_URL}/recurring-tasks/${id}`);
            setRecurringTasks(recurringTasks.filter(task => task.id !== id));
        } catch (error) {
            console.error('Error deleting recurring task:', error);
        }
    };


    const handleEdit = (task) => {
        setEditingTask({ ...task, nextDue: new Date(task.nextDue), lastDone: task.lastDone ? new Date(task.lastDone) : null });
    };

    const handleEditInputChange = (e) => {
        setEditingTask({ ...editingTask, [e.target.name]: e.target.value });
    };

    const handleEditDateChange = (date, field) => {
        if (date && isValid(date)) {
            let updatedTask = { ...editingTask, [field]: date };

            if (field === 'lastDone' && date) {
                const nextDue = calculateNextDueDate(date, updatedTask.frequency);
                updatedTask = { ...updatedTask, nextDue };
            }

            setEditingTask(updatedTask);
        } else {
            console.warn('Invalid date entered');
        }
    };

    const calculateNextDueDate = (lastDone, frequency) => {
        const date = new Date(lastDone);
        switch (frequency) {
            case 'weekly':
                date.setDate(date.getDate() + 7);
                break;
            case 'monthly':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'biannual':
                date.setMonth(date.getMonth() + 6);
                break;
            case 'annual':
                date.setFullYear(date.getFullYear() + 1);
                break;
            default:
                break;
        }
        return date;
    };

    const handleSaveEdit = async () => {
        await handleUpdate(editingTask.id, editingTask);
        setEditingTask(null);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div className="p-4 bg-gray-900 min-h-screen text-gray-200">
            <h2 className="text-2xl font-bold mb-6 text-center">Recurring Tasks</h2>

            <ThemeProvider theme={datePickerTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/* New Task Form */}
                    <form onSubmit={handleSubmit} className="mb-8 bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h3 className="text-xl font-semibold mb-4">Add New Recurring Task</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label htmlFor="title" className="block text-sm font-medium mb-1">Task Title</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={newTask.title}
                                    onChange={handleInputChange}
                                    placeholder="Task Title..."
                                    className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="frequency" className="block text-sm font-medium mb-1">Frequency</label>
                                <select
                                    id="frequency"
                                    name="frequency"
                                    value={newTask.frequency}
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="biannual">Every 6 months</option>
                                    <option value="annual">Annual</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="preparationTime" className="block text-sm font-medium mb-1">Preparation Time (days)</label>
                                <input
                                    type="number"
                                    id="preparationTime"
                                    name="preparationTime"
                                    value={newTask.preparationTime}
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                    min="0"
                                />
                            </div>
                            <div>
                                <label htmlFor="range" className="block text-sm font-medium mb-1">Range (days)</label>
                                <input
                                    type="number"
                                    id="range"
                                    name="range"
                                    value={newTask.range}
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                    min="1"
                                />
                            </div>
                            <div>
                                <label htmlFor="nextDue" className="block text-sm font-medium mb-1">Next Due Date</label>
                                {isMobileView ? (
                                    <StyledMobileDatePicker
                                        value={newTask.nextDue}
                                        onChange={(date) => handleDateChange(date, 'nextDue')}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        inputFormat="yyyy-MM-dd"
                                    />
                                ) : (
                                    <StyledDatePicker
                                        value={newTask.nextDue}
                                        onChange={(date) => handleDateChange(date, 'nextDue')}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        inputFormat="yyyy-MM-dd"
                                    />
                                )}
                            </div>
                        </div>
                        <button type="submit" className="mt-6 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                            Add Task
                        </button>
                    </form>

                    {/* Task List */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {recurringTasks.map(task => (
                            <div key={task.id} className="bg-gray-800 p-6 rounded-lg shadow-lg relative">
                                {editingTask && editingTask.id === task.id ? (
                                    // Editing mode
                                    <>
                                        <div className="absolute top-2 right-2 flex space-x-2">
                                            <button
                                                onClick={handleSaveEdit}
                                                className="text-gray-400 hover:text-green-500 focus:outline-none"
                                                title="Save"
                                            >
                                                <span className="material-icons text-xl">save</span>
                                            </button>
                                        </div>
                                        <div className="mt-8">
                                            <input
                                                type="text"
                                                name="title"
                                                value={editingTask.title}
                                                onChange={handleEditInputChange}
                                                className="w-full px-3 py-2 mb-3 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                            />
                                            <div className="mb-2">
                                                <strong>Frequency:</strong>
                                                <select
                                                    name="frequency"
                                                    value={editingTask.frequency}
                                                    onChange={(e) => {
                                                        const newFrequency = e.target.value;
                                                        const newNextDue = calculateNextDueDate(editingTask.lastDone || new Date(), newFrequency);
                                                        setEditingTask({
                                                            ...editingTask,
                                                            frequency: newFrequency,
                                                            nextDue: newNextDue
                                                        });
                                                    }}
                                                    className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                                >
                                                    <option value="weekly">Weekly</option>
                                                    <option value="monthly">Monthly</option>
                                                    <option value="biannual">Every 6 months</option>
                                                    <option value="annual">Annual</option>
                                                </select>
                                            </div>
                                            <div className="mb-2">
                                                <strong>Preparation Time (days):</strong>
                                                <input
                                                    type="number"
                                                    name="preparationTime"
                                                    value={editingTask.preparationTime}
                                                    onChange={handleEditInputChange}
                                                    className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                                    min="0"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <strong>Range (days):</strong>
                                                <input
                                                    type="number"
                                                    name="range"
                                                    value={editingTask.range}
                                                    onChange={handleEditInputChange}
                                                    className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                                    min="1"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <strong>Last Done: </strong>
                                                {isMobileView ? (
                                                    <StyledMobileDatePicker
                                                        value={editingTask.lastDone}
                                                        onChange={(date) => handleEditDateChange(date, 'lastDone')}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        inputFormat="yyyy-MM-dd"
                                                    />
                                                ) : (
                                                    <StyledDatePicker
                                                        value={editingTask.lastDone}
                                                        onChange={(date) => handleEditDateChange(date, 'lastDone')}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        inputFormat="yyyy-MM-dd"
                                                    />
                                                )}
                                            </div>
                                            <div className="mb-2">
                                                <strong>Next Due: </strong>
                                                {isMobileView ? (
                                                    <StyledMobileDatePicker
                                                        value={editingTask.nextDue}
                                                        onChange={(date) => handleEditDateChange(date, 'nextDue')}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        inputFormat="yyyy-MM-dd"
                                                    />
                                                ) : (
                                                    <StyledDatePicker
                                                        value={editingTask.nextDue}
                                                        onChange={(date) => handleEditDateChange(date, 'nextDue')}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        inputFormat="yyyy-MM-dd"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    // Display mode
                                    <>
                                        <div className="absolute top-2 right-2 flex space-x-2">
                                            <button
                                                onClick={() => handleEdit(task)}
                                                className="text-gray-400 hover:text-blue-500 focus:outline-none"
                                                title="Edit"
                                            >
                                                <span className="material-icons text-xl">edit</span>
                                            </button>
                                            {taskToDelete === task.id ? (
                                                <div className="flex items-center space-x-1">
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDelete(task.id);
                                                            setTaskToDelete(null);
                                                        }}
                                                        className="text-white hover:text-green-500 transition duration-150 ease-in-out"
                                                        title="Confirm Delete"
                                                    >
                                                        <span className="material-icons text-xl">check</span>
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setTaskToDelete(null);
                                                        }}
                                                        className="text-white hover:text-red-500 transition duration-150 ease-in-out"
                                                        title="Cancel Delete"
                                                    >
                                                        <span className="material-icons text-xl">close</span>
                                                    </button>
                                                </div>
                                            ) : (
                                                <button
                                                    onClick={() => setTaskToDelete(task.id)}
                                                    className="text-gray-400 hover:text-red-500 focus:outline-none"
                                                    title="Delete"
                                                >
                                                    <span className="material-icons text-xl">delete</span>
                                                </button>
                                            )}
                                        </div>
                                        <h3 className="text-lg font-semibold mb-3">{task.title}</h3>
                                        <p className="mb-2"><strong>Frequency:</strong> {capitalizeFirstLetter(task.frequency)}</p>
                                        <p className="mb-2"><strong>Preparation Time:</strong> {task.preparationTime} days</p>
                                        <p className="mb-2"><strong>Range:</strong> {new Date(task.nextDue).toLocaleDateString()} to {new Date(new Date(task.nextDue).getTime() + task.range * 24 * 60 * 60 * 1000).toLocaleDateString()}</p>
                                        <p className="mb-2"><strong>Next Due:</strong> {new Date(task.nextDue).toLocaleDateString()}</p>
                                        <p className="mb-4"><strong>Last Done:</strong> {task.lastDone ? new Date(task.lastDone).toLocaleDateString() : 'Not done yet'}</p>
                                        <div className="flex justify-center">
                                            <button
                                                onClick={() => handleUpdate(task.id, { ...task, lastDone: new Date().toISOString() })}
                                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center"
                                            >
                                                <span className="material-icons mr-2">done</span>
                                                Mark as Done
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
};

export default RecurringTasks;
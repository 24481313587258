import React, { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { getSelectionStyles } from './colorUtils';

const MeetingsSidebar = ({
    meetings,
    selectedMeeting,
    onSelectMeeting,
    onAddMeeting,
    onDeleteMeeting,
    isExpanded,
    onToggleSidebar
}) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for latest to earliest, 'asc' for earliest to latest

    const handleDeleteClick = (e, meetingId) => {
        e.stopPropagation();
        setDeleteConfirmation(meetingId);
    };

    const handleDeleteConfirm = (e, meetingId) => {
        e.stopPropagation();
        onDeleteMeeting(meetingId);
        setDeleteConfirmation(null);
    };

    const handleDeleteCancel = (e) => {
        e.stopPropagation();
        setDeleteConfirmation(null);
    };

    const sortedMeetings = useMemo(() => {
        return [...meetings].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
        });
    }, [meetings, sortOrder]);

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    return (
        <div className={`absolute top-0 bottom-0 left-0 z-30 bg-gray-700 transition-all duration-300 ease-in-out border-r border-gray-600 flex flex-col
            ${isExpanded ? 'w-64' : 'w-16'}`}>
            <div className="flex items-center justify-between p-4 border-b border-gray-600">
                {isExpanded && (
                    <button
                        onClick={onAddMeeting}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
                    >
                        <span className="material-icons mr-2">add</span>
                        Add Meeting
                    </button>
                )}
                <button
                    onClick={onToggleSidebar}
                    className={`p-2 hover:bg-gray-600 transition-colors duration-200 ${isExpanded ? '' : 'flex items-center justify-center w-full h-full'}`}
                >
                    <span className="material-icons">
                        {isExpanded ? 'chevron_left' : 'chevron_right'}
                    </span>
                </button>
            </div>

            <div className="flex-grow overflow-y-auto custom-scrollbar">
                {isExpanded && (
                    <>
                        <div className="flex justify-between items-center px-4 py-2 border-b border-gray-700">
                            <button
                                onClick={toggleSortOrder}
                                className="text-gray-400 hover:text-gray-200 transition-colors duration-200 flex items-center"
                            >
                                <span className="material-icons mr-1 text-lg">
                                    {sortOrder === 'desc' ? 'arrow_downward' : 'arrow_upward'}
                                </span>
                                <span className="text-sm">Sort</span>
                            </button>
                        </div>
                        <ul className="space-y-2 p-4">
                            {sortedMeetings.map((meeting) => (
                                <li
                                    key={meeting.id}
                                    className={`p-2 rounded cursor-pointer transition-all duration-200 flex items-center justify-between
                                    ${getSelectionStyles(selectedMeeting?.id === meeting.id)}
                                    relative overflow-hidden`}
                                    onClick={() => onSelectMeeting(meeting)}
                                >
                                    <div className="flex items-center flex-grow min-w-0">
                                        <span className="material-icons mr-2 text-blue-400">
                                            event
                                        </span>
                                        <div className="flex flex-col truncate">
                                            <span className="font-medium text-gray-200 truncate">
                                                {meeting.title}
                                            </span>
                                            <span className="text-xs text-gray-400">
                                                {format(new Date(meeting.date), 'MMM d, yyyy h:mm a')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-center ml-2">
                                        {deleteConfirmation === meeting.id ? (
                                            <div className="flex items-center space-x-1">
                                                <button
                                                    onClick={(e) => handleDeleteConfirm(e, meeting.id)}
                                                    className="text-white hover:text-gray-200 transition duration-150 ease-in-out"
                                                >
                                                    <span className="material-icons text-sm">check</span>
                                                </button>
                                                <button
                                                    onClick={handleDeleteCancel}
                                                    className="text-white hover:text-gray-200 transition duration-150 ease-in-out"
                                                >
                                                    <span className="material-icons text-sm">close</span>
                                                </button>
                                            </div>
                                        ) : (
                                            <button
                                                onClick={(e) => handleDeleteClick(e, meeting.id)}
                                                className="text-white hover:text-gray-200 transition-colors duration-200"
                                            >
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
};

export default MeetingsSidebar;
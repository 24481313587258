import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TodoListActions from './TodoListActions';
import SendToProjectDialog from './SendToProjectDialog';

// WeeklyTodoListActions component handles actions for weekly todos, including sending to projects
const WeeklyTodoListActions = ({ todo, onDelete, onToggleUrgent, projects, onSendToProject, onCreateNewProject }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => setIsDialogOpen(false);

    const handleSendToProject = (todo, projectId) => {
        console.log('WeeklyTodoListActions: Sending todo to project:', todo, 'Project ID:', projectId);
        try {
            onSendToProject(todo, projectId);
            handleCloseDialog();
        } catch (error) {
            console.error('Error sending todo to project:', error);
        }
    };

    const handleCreateNewProject = (projectName) => {
        console.log('WeeklyTodoListActions: Creating new project:', projectName);
        try {
            const newProjectId = onCreateNewProject(projectName);
            console.log('WeeklyTodoListActions: New project created with ID:', newProjectId);
            return newProjectId;
        } catch (error) {
            console.error('Error creating new project:', error);
            // TODO: Add user-friendly error handling (e.g., show an error message to the user)
            return null;
        }
    };

    // Check if the todo is already in a project
    const isInProject = todo.projectId != null;

    return (
        <div className="flex items-center space-x-1 md:space-x-2 ml-2 mt-2 md:mt-0">
            {!isInProject && (
                <button
                    onClick={handleOpenDialog}
                    className="text-white hover:text-blue-300 transition-colors duration-200 p-1 md:p-1"
                    title="Send to Project"
                >
                    <span className="material-icons text-xl md:text-2xl">create_new_folder</span>
                </button>
            )}
            <SendToProjectDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                projects={projects}  // Pass the projects prop here
                onSendToProject={handleSendToProject}
                onCreateNewProject={handleCreateNewProject}
                todo={todo}
            />
            <TodoListActions todo={todo} onDelete={onDelete} onToggleUrgent={onToggleUrgent} />
        </div>
    );
};

WeeklyTodoListActions.propTypes = {
    todo: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // Add other todo properties as needed
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onToggleUrgent: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        // Add other project properties as needed
    })).isRequired,
    onSendToProject: PropTypes.func.isRequired,
    onCreateNewProject: PropTypes.func.isRequired,
};

export default WeeklyTodoListActions;
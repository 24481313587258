import React from 'react';
import headerLogo from '../images/doodles_mascot.png';

const Header = ({ user, onLogout }) => {
    const formatUsername = (username) => {
        if (!username) return '';
        return username.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };


    return (
        <header className="relative bg-gradient-to-b from-gray-900 to-gray-800 text-white p-2 sm:p-4">
            <div className="flex items-center justify-between">
                <div className="flex-shrink-0 w-24 sm:w-32">
                    {/* Left side empty for balance */}
                </div>
                <div className="flex items-center justify-center flex-grow">
                    <img
                        src={headerLogo}
                        alt="Doodles"
                        className="h-8 w-8 sm:h-12 sm:w-12 md:h-16 md:w-16 lg:h-20 lg:w-20 mr-2 sm:mr-4"
                    />
                    <h1 className="text-lg sm:text-3xl md:text-4xl lg:text-5xl font-bold chewy-regular whitespace-nowrap">Bearly Functional</h1>
                </div>
                <div className="flex-shrink-0 flex items-center justify-end space-x-2 sm:space-x-4">
                    {user && (
                        <>
                            <span className="text-xs sm:text-sm truncate hidden sm:inline whitespace-nowrap">
                                Welcome, {formatUsername(user.username)}
                            </span>
                            <button
                                onClick={onLogout}
                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 sm:py-2 sm:px-4 rounded text-xs sm:text-sm transition duration-300 whitespace-nowrap"
                            >
                                Logout
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-1 sm:h-2 bg-gradient-to-b from-transparent to-gray-900"></div>
        </header>
    );
};

export default Header;